import {updateDoc, addDoc, collection, doc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {removeEmptyFields, showErrorsListInToast} from "../../../common";
import {useState} from "react";
import {CarType} from "../../../fb-converters/car-converter";


export interface CarsDetailsProps {
    zIndex?: number;
    car: CarType | null;
    onDone: () => any;
}

export function CarsDetails({
                                       zIndex,
                                       car,
                                       onDone
                                   }: CarsDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        const objectsRef = collection(fbDb, "cars");
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (car?.id) {
            await updateDoc(doc(objectsRef, car?.id), _data);
        } else {
            await addDoc(objectsRef, _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const data: any = {
            name: event.target.name.value,
            regPlate: event.target.regPlate.value,
        };

        const errors = [];
        if (!data.name || data.name.trim().length === 0) {
            errors.push("Моля, въведете марка и/или модел");
        }
        if (!data.regPlate || data.regPlate.trim().length === 0) {
            errors.push("Моля, въведете регистрационен номер");
        }
        if (errors.length === 0) {
            save(data);
        } else {
            showErrorsListInToast("Внимание", errors);
        }
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {car ? "Корекция" : "Добавяне"} на превозно средство
                </h5>

                <Form.Group>
                    <Form.Label>
                        Марка, модел
                    </Form.Label>
                    <Form.Control type="text" name="name" defaultValue={car?.name}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        Регистрационен номер
                    </Form.Label>
                    <Form.Control type="text" name="regPlate" defaultValue={car?.regPlate}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
