import {FC} from "react";
import {Form, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import MinusIconButton from "../../../common/icon-buttons/MinusIconButton";

interface Props { onChange: (services: string[]) => void, services: string[] }

const CustomerInquiryServices: FC<Props> = (props) => {
    function addNewService() {
        props.onChange([...props.services, ""]);
    }

    function removeService(idx: number) {
        const _services = [...props.services];
        _services.splice(idx, 1);
        props.onChange(_services);
    }

    function updateService(idx: number, s: string) {
        const _services = [...props.services];
        _services[idx] = s;
        props.onChange(_services);
    }

    return <Table>
        <thead>
        <tr>
            <RightAlignedCell header className={"w-50px"}>No:</RightAlignedCell>
            <th>Услуга</th>
            <th/>
        </tr>
        </thead>
        <tbody>
        {
            props.services.map(
                (s: string, idx: number) => <tr key={idx}>
                    <RightAlignedCell><span className={"d-inline-block lh-2-5em"}> { idx+1 } </span></RightAlignedCell>
                    <td>
                        <Form.Control value={s}
                                      onChange={event => updateService(idx, event.target.value)} />
                    </td>
                    <RightAlignedCell className={"w-50px"}>
                        <MinusIconButton onClick={() => removeService(idx)} />
                    </RightAlignedCell>
                </tr>
            )
        }
        <tr>
            <RightAlignedCell colSpan={3}>
                <PlusIconButton onClick={() => addNewService()} />
            </RightAlignedCell>
        </tr>
        </tbody>
    </Table>;
}

export default CustomerInquiryServices;
