import {CrmPeriodProps} from "./CrmCalendar";
import {Component, useEffect, useState} from "react";
import {Button, Col, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useDocument} from "react-firebase-hooks/firestore";
import {doc, getFirestore} from "firebase/firestore";
import {firebaseApp} from "../../App";
import CrmStatisticsViewer from "./CrmStatisticsViewer";


export default function CrmPeriodYearViewer({period, statistics, changePeriodType}: CrmPeriodProps) {


    function showYearDetails(year: number) {
        const newPeriod = {...period, year: year};
        changePeriodType("month", newPeriod);
    }


    let tableContent: any[] = [];
    if (statistics) {
        const _data = statistics.data() || {};
        const _keys = Object.keys(_data).sort((k1, k2) => k1.localeCompare(k2)).reverse();
        _keys?.forEach(
            k => {
                const item = _data[k];
                const _y = k.replace('y', '');
                tableContent.push(
                    <tr key={k}>
                        <td>{_y}</td>
                        <td><CrmStatisticsViewer data={item[k]}/></td>
                        <td className={"text-end"}>
                            <Button onClick={e => showYearDetails(parseInt(_y))} size={"sm"}>
                                <FontAwesomeIcon icon={faArrowRight}/>
                            </Button>
                        </td>
                    </tr>
                )
            }
        )
    } else tableContent = [];


    return <>
        <Row>
            <Col>
                <Table>
                    <thead>
                    <tr>
                        <th>Година</th>
                        <th>Активности</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {tableContent}
                    </tbody>

                </Table>
            </Col>
        </Row>
    </>
}