import {
    CustomerOfferProductionAttributesType,
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import React from "react";
import OfferAttrPdfTableHeader from "./OfferAttrPdfTableHeader";
import OfferAttrPdfTableRow from "./OfferAttrPdfTableRow";
import {View} from "@react-pdf/renderer";

interface OfferPdfTableProps {
    attributes: CustomerOfferProductionAttributesType;
    productQty: number;
}

const OfferProductPdfTable = ({attributes, productQty}: OfferPdfTableProps) => {
    return (<View wrap={false}>
        <OfferAttrPdfTableHeader/>
        <OfferAttrPdfTableRow attributes={attributes} productQty={productQty}/>
    </View>);
};

export default OfferProductPdfTable;
