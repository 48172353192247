import {Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import EditableTableCell from "../../../common/EditableTableCell";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {textToFloat, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsNumber} from "../../../common";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import ListIconButton from "../../../common/icon-buttons/ListIconButton";
import React, {useState} from "react";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {
    CustomerOfferProductionAttributesType,
    CustomerOfferProductionAttributeType,
    CustomerOfferProductionType,
    CustomerOfferType
} from "../../../fb-converters/cutomerOffer-converter";
import {VirtualProductsDialog} from "../../nomenclatures/virtual-products/VirtualProductsDialog";
import {VirtualProductType} from "../../nomenclatures/virtual-products/hooks/useVirtualProductsOnce";

interface Props {
    zIndex?: number;
    discount_price: number;
    eurExchangeRate: number;
    qty: number
    attributes: CustomerOfferProductionAttributesType;
    setAttributes: (attributes: CustomerOfferProductionAttributesType) => void;
}

export default function CustomerOfferDetailsAttributes({
                                                         zIndex,
                                                         discount_price,
                                                         eurExchangeRate,
                                                           qty,
                                                         attributes,
                                                         setAttributes
                                                     }: Props) {
    const [editCell, setEditCell] = useState("");

    return (
        <>
            <Row>
                <Col xs={"12"}><strong>Допълнителни разходи</strong></Col>
                <Col xs={"12"}>
                    <Table className={"border"}>
                        <thead>
                        <tr>
                            <RightAlignedCell header className={"w-75px"}>No:</RightAlignedCell>
                            <th>Вид</th>
                            <RightAlignedCell header>Ед.цена<br/>(лв)</RightAlignedCell>
                            <RightAlignedCell header>Отстъпка<br/>(%)</RightAlignedCell>
                            <RightAlignedCell header>Цена след<br/>отст., лв</RightAlignedCell>
                            <RightAlignedCell header>Кол.</RightAlignedCell>
                            <RightAlignedCell header>Цена<br/>(лева)</RightAlignedCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            attributes?.map(
                                (a, aIdx) => <tr key={aIdx}>
                                    <RightAlignedCell> {1 + aIdx} </RightAlignedCell>
                                    <td> {a.name} </td>
                                    <RightAlignedCell>
                                        {zeroToEmptyStringAsCurrencyWithCommas(a.singlePrice.toString())} </RightAlignedCell>

                                    <EditableTableCell inEditMode={editCell === `${aIdx}-attr-discount`}
                                                       className={"w-120px text-end"}
                                                       isWorking={false}
                                                       value={zeroToEmptyStringAsCurrencyWithCommas(a.discount.toString())}
                                                       onEdit={() => setEditCell(`${aIdx}-attr-discount`)}
                                                       onBlur={newValue => {
                                                           const _attributes = [...attributes];
                                                           const price = _attributes[aIdx].singlePrice || 0;
                                                           const discount = textToFloat(newValue) || 0;
                                                           const finPrice = price - (price * discount / 100);

                                                           _attributes[aIdx].discount = discount;
                                                           _attributes[aIdx].finalPrice = finPrice;
                                                           setAttributes(_attributes);
                                                           setEditCell("");
                                                       }}
                                                       onDone={() => setEditCell("")}/>

                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(a.finalPrice.toString())} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsNumber(qty) } </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas((a.finalPrice * qty).toString())} </RightAlignedCell>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
