import {
    CustomerOfferProductionType,
    CustomerOfferType
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import OfferProductPdfTableHeader from "./OfferProductPdfTableHeader";
import React from "react";
import OfferProductPdfTableRow from "./OfferProductPdfTableRow";
import { View } from "@react-pdf/renderer";

interface OfferPdfTableProps {
    offer: CustomerOfferType;
    productIndex: number;
}

const OfferProductPdfTable = ({productIndex, offer}: OfferPdfTableProps) => {
    console.log("OfferProductPdfTable - offer: ", offer);
    const product: CustomerOfferProductionType = offer.products[productIndex];
    let savedPrice = (product.singlePrice - product.finalSinglePrice) * product.qty;

    if (product.services?.length > 0) {
        product.services.forEach(service => {
            savedPrice += (service.singlePrice - service.finalPrice) * service.qty * product.qty;
        });
    }

    if (product.attributes?.length > 0) {
        product.attributes.forEach(attribute => {
            savedPrice += (attribute.singlePrice - attribute.finalPrice) * product.qty;
        });
    }

    const price = (product.singlePrice || 0);
    const discount = product.discount || 0;
    const finalPrice = price - (price * discount / 100);
    const isStarred = productIndex === offer.bestProductPriceIdx;

    return (<View wrap={false}>
        <OfferProductPdfTableHeader savedPrice={savedPrice} isStarred={isStarred}/>
        <OfferProductPdfTableRow productIndex={productIndex} product={product} finalPrice={finalPrice} />
    </View>);
};

export default OfferProductPdfTable;
