import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useMemo, useState} from "react";
import {customerOfferConverter, CustomerOfferType} from "../../../fb-converters/cutomerOffer-converter";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {collection, deleteDoc, getDocs, orderBy, query, QueryDocumentSnapshot, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import RightAlignedCell from "../../../common/RightAlignedCell";
import {dateToString} from "../../../common";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import EditIconButton from "../../../common/icon-buttons/EditIconButton";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import {
    customerInvoiceConverter,
    CustomerInvoiceItemsType, CustomerInvoicesType,
    CustomerInvoiceType,
    EMPTY_CUSTOMER_INVOICE_PARTY
} from "../../../fb-converters/cutomerInvoice-converter";
import {OwnCompanyType, ownCopanyConverter} from "../../../fb-converters/own-company-converter";
import ProductionPlanButton from "../../production/production-plan/ProductionPlanButton";
import PrintIconButton from "../../../common/icon-buttons/PrintIconButton";
import CustomerInvoiceDetails from "../customers-invoices/CustomerInvoiceDetails";
import InvoicePrintPreview from "./print/invoice/InvoicePrintPreview";
import ConfirmationPrintPreview from "./print/confirmation/ConfirmationPrintPreview";


export interface CustomerOffersListProps {
    isVisible: boolean;
    inquiry: CustomerInquiryType;
    onAdd: () => any;
    onPrint: (item: CustomerOfferType) => any;
    onEdit: (item: CustomerOfferType) => any;
    onDelete: (item: CustomerOfferType) => any;
    onClose: (result: CustomerOfferType | null) => any;
    onSelect?: (result: CustomerOfferType) => any;
}

export function CustomerOffersList({
                                       isVisible,
                                       inquiry,
                                       onAdd,
                                       onPrint,
                                       onEdit,
                                       onDelete,
                                       onClose,
                                       onSelect
                                   }: CustomerOffersListProps) {
    const collectionRef = collection(fbDb, `customerInquiries/${inquiry.id}/offers`).withConverter(customerOfferConverter);
    const [offers, loadingOffers, errorOffers] = useCollection(collectionRef);

    const _offersIds = offers?.docs.map(d => d.id) || [];
    if (_offersIds.length === 0) {
        _offersIds.push("");
    }
    const [invoices, loadingInvoices, errorInvoices] =
        useCollection(query(collection(fbDb, "customerInvoices"),
            where("offerId", "in", _offersIds)
        ).withConverter(customerInvoiceConverter));

    const [offerInvoices, setOfferInvoices] = useState<CustomerInvoicesType | undefined>(undefined);

    const [deleteOffer, setDeleteOffer] = useState<string>("");
    const [invoice, setInvoice] = useState<CustomerInvoiceType | undefined>(undefined);
    const [printInvoice, setPrintInvoice] = useState<CustomerInvoiceType | undefined>(undefined);
    const [printConfirmation, setPrintConfirmation] = useState<CustomerInvoiceType | undefined>(undefined);
    const [availableItems, setAvailableItems] = useState<CustomerInvoiceItemsType>([]);

    const [ownCompanies, setOwnCompanies] = useState<OwnCompanyType[]>([]);

    async function loadOwnCompanies() {
        const ownCompaniesData = await getDocs(query(collection(fbDb, "ownCompanies"),
            orderBy("listIndex", "asc")).withConverter(ownCopanyConverter));
        setOwnCompanies(ownCompaniesData.docs.map(d => d.data()))
    }

    useEffect(() => {
        loadOwnCompanies();
    }, []);

    useEffect(() => {
        if (invoices) {
            setOfferInvoices(invoices.docs.map(d => d.data()) || []);
        }
    }, [invoices]);


    if (!isVisible) return null;

    function doDeleteOffer(o: QueryDocumentSnapshot<CustomerOfferType>) {
        deleteDoc(o.ref);
    }

    function getItemsFromOffer(o: QueryDocumentSnapshot<CustomerOfferType>) {
        debugger;
        const confirmation = getInvoices(o, "потвърждение на поръчка");
        if(confirmation && confirmation.length === 1) {
            const _items = confirmation[0].items;
            setAvailableItems(_items);
            return _items;
        }

        const offer = o.data();
        const availableItems = offer?.products.map(
            p => {
                const _items: CustomerInvoiceItemsType = [];
                _items.push(
                    {
                        productId: p.product.id,
                        code: p.product.sku || "",
                        name: p.product.name,
                        discount: p.discount,
                        _length: p.product._length || 0,
                        width: p.product.width || 0,
                        height1: p.product.height1 || 0,
                        height2: p.product.height2 || 0,
                        qty: p.qty || 1,
                        price: p.finalSinglePrice,
                        listIdx: -1,
                        total: p.finalSinglePrice * p.qty,
                        dimension: p.product.dimens || "брой",
                        weight: p.product.weightWithElectrolit || 0,
                        ownProduction: false,
                    }
                );

                p.services?.forEach(
                    s => {
                        _items.push(
                            {
                                productId: null,
                                code: "",
                                name: `${s.service.name} [${p.product.name}]`,
                                discount: s.discount,
                                _length: 0,
                                width: 0,
                                height1: 0,
                                height2: 0,
                                qty: 1,
                                price: s.finalPrice,
                                listIdx: -1,
                                total: s.finalPrice,
                                dimension: "услуга",
                                weight: 0,
                                ownProduction: false,
                            }
                        );
                    }
                )
                return _items;
            }
        )?.flat() || [];
        setAvailableItems(availableItems);
        return availableItems;
    }

    function addDocument(o: QueryDocumentSnapshot<CustomerOfferType>, documentType: string) {
        const _inv: CustomerInvoiceType = {
            offerId: o.id,
            offerNumber: o.data().offerNumber,
            offerDate: o.data().offerDate,
            payType: "по банка",
            iban: "",
            bankCode: "",
            dialPlace: "",
            dialDescription: "",
            dialReason: "",
            taxEventDate: new Date(),
            deliveryDate: new Date(),
            invoiceDate: new Date(),
            receiver: inquiry.location && inquiry.client ? {
                companyId: "",
                clientId: inquiry.client.id || "",
                locationId: inquiry.location.id || "",
                ddsNumber: inquiry.client.bulstat || "",
                bulstat: inquiry.client.bulstat || "",
                name: inquiry.client.name || inquiry.location.name || "",
                phone: inquiry.client.phone || inquiry.location.phone || "",
                mol: inquiry.client.mol || inquiry.location.mol || "",
                address: inquiry.client.address || inquiry.location.address || "",
                city: inquiry.client.region || "",
                email: inquiry.client.email || "",
            } : EMPTY_CUSTOMER_INVOICE_PARTY,
            sender: inquiry.ownCompany ? {
                companyId: inquiry.ownCompany.id!,
                clientId: "",
                locationId: "",
                ddsNumber: inquiry.ownCompany.ddsNumber,
                bulstat: inquiry.ownCompany.bulstat,
                name: inquiry.ownCompany.name,
                phone: inquiry.ownCompany.phone,
                mol: inquiry.ownCompany.mol,
                address: inquiry.ownCompany.address,
                city: inquiry.ownCompany.city,
                email: inquiry.ownCompany.email || "",
            } : EMPTY_CUSTOMER_INVOICE_PARTY,
            invoiceNumber: -1,
            bank: "",
            documentType: documentType,
            items: getItemsFromOffer(o),
        }

        if (documentType === "проформа фактура") {
            const _orderConfirmation = getInvoices(o, "потвърждение на поръчка");
            if (_orderConfirmation && _orderConfirmation.length > 0) {
                setInvoice({
                    ..._orderConfirmation[0],
                    id: undefined,
                    ref: undefined,
                    taxEventDate: new Date(),
                    deliveryDate: new Date(),
                    invoiceDate: new Date(),
                    invoiceNumber: -1,
                    documentType: "проформа фактура",
                });
                return;
            }
        }

        if (documentType === "фактура") {
            const _orderConfirmation = getInvoices(o, "проформа фактура");
            if (_orderConfirmation && _orderConfirmation.length > 0) {
                setInvoice({
                    ..._orderConfirmation[0],
                    id: undefined,
                    ref: undefined,
                    taxEventDate: new Date(),
                    deliveryDate: new Date(),
                    invoiceDate: new Date(),
                    invoiceNumber: -1,
                    documentType: "фактура",
                });
                return;
            } else {
                const _orderConfirmation = getInvoices(o, "потвърждение на поръчка");
                if (_orderConfirmation && _orderConfirmation.length === 1) {
                    setInvoice({
                        ..._orderConfirmation[0],
                        id: undefined,
                        ref: undefined,
                        taxEventDate: new Date(),
                        deliveryDate: new Date(),
                        invoiceDate: new Date(),
                        invoiceNumber: -1,
                        documentType: "фактура",
                    });
                    return;
                }
            }
        }

        setInvoice(_inv);
    }

    const zIndex = 1400;

    function getInvoices(o: QueryDocumentSnapshot<CustomerOfferType>, invoiceType: string) {
        const invs = offerInvoices?.filter(i => i && i.offerId === o.id && i.documentType === invoiceType);
        return invs;
    }

    function getInvoiceNumberAndDate(o: QueryDocumentSnapshot<CustomerOfferType>, inv: CustomerInvoiceType, invoiceType: string) {
        let _result = `Няма ${invoiceType}`;
        if (inv) {
            _result = `${inv.invoiceNumber} / ${dateToString(inv.invoiceDate)}`;
        }
        return _result;
    }

    function editDocument(_inv: CustomerInvoiceType) {
        setAvailableItems([..._inv.items]);
        setInvoice({..._inv});
    }

    function doPrintInvoice(_invoice: CustomerInvoiceType) {
        setPrintInvoice(_invoice);
    }

    function doPrintConfirmation(_orderConfirmation: any) {
        setPrintConfirmation(_orderConfirmation);
    }

    return (
        <>
            <ModalBody>
                <LoadingErrorContent loading={loadingOffers} error={errorOffers?.message || ""}>
                    <Table>
                        <thead>
                        <tr>
                            <RightAlignedCell header>Номер</RightAlignedCell>
                            <th>Дата</th>
                            <th>Потвърждение на поръчка</th>
                            <th>Проформа фактура</th>
                            <th>Фактура</th>
                            <th className={"w-75px"}/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            offers?.docs.map(
                                o => {
                                    const orderConfirmations = getInvoices(o, "потвърждение на поръчка");
                                    const proformaInvoices = getInvoices(o, "проформа фактура");
                                    const invoices = getInvoices(o, "фактура");

                                    return <tr key={o.id}>
                                        <RightAlignedCell> {o.data().offerNumber} </RightAlignedCell>
                                        <td> {dateToString(o.data().offerDate)} </td>
                                        <td>
                                            {
                                                orderConfirmations ? <>
                                                        {
                                                            orderConfirmations.length === 0 &&
                                                            <Row>
                                                                <Col xs={"auto"}>
                                                                    Няма потвърждение на поръчка
                                                                </Col>
                                                                <Col>
                                                                    <PlusIconButton size={"sm"}
                                                                                    onClick={() => addDocument(o, "потвърждение на поръчка")}/>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {

                                                            orderConfirmations.map(
                                                                offerInvoice =>

                                                                    <Row>
                                                                        <Col xs={"auto"}>
                                                                            <strong>{getInvoiceNumberAndDate(o, offerInvoice, "потвърждение на поръчка")}</strong>
                                                                        </Col>
                                                                        <Col>
                                                                            {
                                                                                offerInvoice &&
                                                                                <Row className={"p-0 m-0"}>
                                                                                    <Col xs={"auto"} className={"p-0 m-0"}>
                                                                                        <EditIconButton size={"sm"}
                                                                                                        onClick={() => editDocument(offerInvoice)}/>
                                                                                    </Col>
                                                                                    <Col xs={"auto"} className={"p-0 m-0"}>
                                                                                        <ProductionPlanButton
                                                                                            inquiry={inquiry}
                                                                                            invoice={offerInvoice}/>
                                                                                    </Col>
                                                                                    <Col xs={"auto"} className={"p-0 m-0"}>
                                                                                        <PrintIconButton
                                                                                            onClick={() => doPrintConfirmation(offerInvoice)}
                                                                                            size={"sm"}/>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                            )
                                                        }
                                                    </>
                                                    : <Spinner animation={"border"}/>
                                            }
                                        </td>
                                        <td>
                                            {
                                                proformaInvoices ? <>
                                                        {
                                                            proformaInvoices.length === 0 ?
                                                                <Row>
                                                                    <Col xs={"auto"}>
                                                                        Няма проформа фактура
                                                                    </Col>
                                                                    <Col>
                                                                        <PlusIconButton size={"sm"}
                                                                                        onClick={() => addDocument(o, "проформа фактура")}/>
                                                                    </Col>
                                                                </Row>
                                                                : <>
                                                                    {
                                                                        proformaInvoices.map(
                                                                            (offerInvoice, idx) =>

                                                                                <Row>
                                                                                    <Col xs={"auto"}>
                                                                                        <strong>{getInvoiceNumberAndDate(o, offerInvoice, "проформа фактура")}</strong>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        {
                                                                                            offerInvoice &&
                                                                                            <>
                                                                                                <EditIconButton size={"sm"}
                                                                                                                onClick={() => editDocument(offerInvoice)}/>&nbsp;
                                                                                                <PrintIconButton
                                                                                                    onClick={() => doPrintInvoice(offerInvoice)}
                                                                                                    size={"sm"}/>&nbsp;
                                                                                                {
                                                                                                    idx === proformaInvoices.length - 1 &&
                                                                                                    <PlusIconButton size={"sm"}
                                                                                                                    onClick={() => addDocument(o, "проформа фактура")}/>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                        )
                                                                    }
                                                                </>
                                                        }

                                                    </>
                                                    : <Spinner animation={"border"}/>
                                            }
                                        </td>
                                        <td>
                                            {
                                                invoices ? <>
                                                        {
                                                            invoices.length === 0 ?
                                                                <Row>
                                                                    <Col xs={"auto"}>
                                                                        Няма фактура
                                                                    </Col>
                                                                    <Col>
                                                                        <PlusIconButton size={"sm"}
                                                                                        onClick={() => addDocument(o, "фактура")}/>
                                                                    </Col>
                                                                </Row>
                                                                : <>
                                                                    {
                                                                        invoices.map(
                                                                            (offerInvoice, idx) =>

                                                                                <Row>
                                                                                    <Col xs={"auto"}>
                                                                                        <strong>{getInvoiceNumberAndDate(o, offerInvoice, "фактура")}</strong>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        {
                                                                                            offerInvoice &&
                                                                                            <>
                                                                                                <EditIconButton size={"sm"}
                                                                                                                onClick={() => editDocument(offerInvoice)}/>&nbsp;
                                                                                                <PrintIconButton
                                                                                                    onClick={() => doPrintInvoice(offerInvoice)}
                                                                                                    size={"sm"}/>&nbsp;
                                                                                                {
                                                                                                    idx === invoices.length - 1 &&
                                                                                                    <PlusIconButton size={"sm"}
                                                                                                                    onClick={() => addDocument(o, "фактура")}/>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                        )
                                                                    }
                                                                </>
                                                        }

                                                    </>
                                                    : <Spinner animation={"border"}/>
                                            }
                                            {
                                                // offerInvoices ?
                                                //     <Row>
                                                //         <Col xs={"auto"}>
                                                //             <strong>{getInvoiceNumberAndDate(o, "фактура")}</strong>
                                                //         </Col>
                                                //         <Col>
                                                //             {
                                                //                 _invoice ?
                                                //                     <>
                                                //                         <EditIconButton size={"sm"}
                                                //                                         onClick={() => editDocument(_invoice)}/>&nbsp;
                                                //                         <PrintIconButton
                                                //                             onClick={() => doPrintInvoice(_invoice)}
                                                //                             size={"sm"}/>
                                                //                     </>
                                                //                     :
                                                //                     <PlusIconButton size={"sm"}
                                                //                                     onClick={() => addDocument(o, "фактура")}/>
                                                //             }
                                                //         </Col>
                                                //     </Row>
                                                //     : <Spinner animation={"border"}/>
                                            }
                                        </td>
                                        <RightAlignedCell className={"text-nowrap"}>
                                            {
                                                deleteOffer === o.id ? <>
                                                        <CheckIconButton onClick={() => doDeleteOffer(o)}/>&nbsp;
                                                        <CancelIconButton onClick={() => setDeleteOffer("")}/>
                                                    </> :
                                                    <>
                                                        <PrintIconButton
                                                            onClick={() => onPrint && onPrint(o.data())}
                                                            size={"sm"}/>&nbsp;
                                                        <EditIconButton
                                                            onClick={() => onEdit && onEdit(o.data())}/>&nbsp;
                                                        <TrashIconButton onClick={() => setDeleteOffer(o.id)}/>
                                                    </>
                                            }
                                        </RightAlignedCell>
                                    </tr>
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </LoadingErrorContent>
            </ModalBody>

            <ModalFooter>
                <Button onClick={() => onAdd()}>
                    <FontAwesomeIcon icon={faPlus}/> Добави нова
                </Button>
                <Button variant={"outline-secondary"} onClick={() => {
                    onClose(null)
                }}>
                    <FontAwesomeIcon icon={faCancel}/> Затвори
                </Button>
            </ModalFooter>

            {
                invoice &&
                <CustomerInvoiceDetails
                    inquery={inquiry}
                    offer={offers?.docs.find(o => o.id === invoice.offerId)?.data()!}
                    availableItems={availableItems}
                    invoice={invoice}
                    zIndex={zIndex}
                    ownCompanies={ownCompanies}
                    onClose={() => setInvoice(undefined)}
                />
            }

            {
                printInvoice &&
                <InvoicePrintPreview
                    invoice={printInvoice}
                    inquery={inquiry}
                    zIndex={zIndex}
                    onDismiss={() => setPrintInvoice(undefined)}/>
            }

            {
                printConfirmation &&
                <ConfirmationPrintPreview
                    confirmation={printConfirmation}
                    inquery={inquiry}
                    zIndex={zIndex}
                    onDismiss={() => setPrintConfirmation(undefined)}/>
            }
        </>
    )
}
