import {FC, useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {ClientsDialog} from "../../nomenclatures/clients/ClientsDialog";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";

export interface WarehouseReportGeneralFilterType {
    fromDate?: Date | null;
    toDate?: Date | null;
    product?: ProductType;
    client?: ClientType;
}

interface Props {
    filter: WarehouseReportGeneralFilterType;
    onChange: (filter: WarehouseReportGeneralFilterType) => void;
}

const WarehouseReportGeneralFilter: FC<Props> = (props) => {
    const [filter, setFilter] = useState<WarehouseReportGeneralFilterType>({});
    const productsDialog = <ProductsDialog show={true} zIndex={1060} onClose={() => {}}/>
    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {}}/>

    useEffect(() => {
        setFilter({...props.filter});
    }, [props.filter]);

    function applyFilter() {
        props.onChange && props.onChange(filter);
    }

    return <Row>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Начална дата</Form.Label>
                <ReactDatePicker className={"form-control"} isClearable
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     setFilter(prevState => {
                                         prevState.fromDate = d
                                         return prevState;
                                     })
                                 }}
                                 selected={filter.fromDate}
                />
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Крайна дата</Form.Label>
                <ReactDatePicker className={"form-control"} isClearable
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     {
                                         setFilter(prevState => {
                                             prevState.toDate = d
                                             return prevState;
                                         })
                                     }
                                 }}
                                 selected={filter.toDate}
                />
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Продукт:</Form.Label>
                <SelectValueFromDialog value={filter.product?.name || ''}
                                       onChange={(product) => {
                                           setFilter(prevState => {
                                               return {...prevState, product: product}
                                           })
                                       }}
                                       dialog={productsDialog}/>
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Клиент:</Form.Label>
                <SelectValueFromDialog value={filter.client?.name || ''}
                                       onChange={(client) => {
                                           setFilter(prevState => {
                                               return {...prevState, client: client}
                                           })
                                       }}
                                       dialog={clientsDialog}/>
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <div style={{paddingTop: "30px"}}>
                <CheckIconButton onClick={applyFilter}/>
            </div>
        </Col>
    </Row>
}

export default WarehouseReportGeneralFilter;
