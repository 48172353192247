import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type CarType = {
    id?: string;
    ref?: DocumentReference;
    name: string;
    regPlate: string;
}

export const CarConverter = {
    toFirestore(bankAccount: CarType): DocumentData {
        return {
            name: bankAccount.name,
            regPlate: bankAccount.regPlate,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): CarType {
        const bankAccount = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            name: bankAccount.name,
            regPlate: bankAccount.regPlate,
        } as CarType;
    }
};
