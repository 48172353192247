import {Col, Form, Row} from "react-bootstrap";

export interface CheckFilterItem {
    name: string,
    selected: boolean
};

export interface CheckFilterProps {
    title: string;
    options: CheckFilterItem[];
    onChange: (options: CheckFilterItem[]) => void;
}

export default function CheckFilter({title, options, onChange}: CheckFilterProps) {
    function onClick(r: CheckFilterItem) {
        r.selected = !r.selected;
        onChange([...options]);
    }

    return <Row>
        <Col>
            <Row>
                <Col className={"fw-bold"}>{title}</Col>
            </Row>
        </Col>
        <Row>
            {
                options.map(
                    r => <Col key={r.name} className={"text-nowrap overflow-hidden d-inline-block"} style={{textOverflow: "ellipsis"}} xs={"3"}>
                        <Form.Check type={"checkbox"} label={r.name} checked={r.selected}
                        onClick={() => onClick(r)} onChange={() => {}}/></Col>
                )
            }
        </Row>
    </Row>
}
