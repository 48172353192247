import {FC, useEffect, useState} from "react";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {ProviderType} from "../../crm/customers-offers/hooks/useProviders";
import {ClientsDialog} from "../../nomenclatures/clients/ClientsDialog";
import {ProvidersDialog} from "../../nomenclatures/providers/ProvidersDialog";
import {Col, Form, Row} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";

export interface WarehouseInOutFilterType {
    fromDate?: Date | null;
    toDate?: Date | null;
    product?: ProductType;
    client?: ClientType;
    provider?: ProviderType;
}

interface Props {
    viewMode: "in" | "out";
    filter: WarehouseInOutFilterType;
    onChange: (filter: WarehouseInOutFilterType) => void;
}

const WarehouseInOutFilter: FC<Props> = (props) => {
    const [filter, setFilter] = useState<WarehouseInOutFilterType>({});
    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const providerDialog = <ProvidersDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const productsDialog = <ProductsDialog zIndex={1060} show={true} onClose={()=>{}} />

    useEffect(() => {
        setFilter({...props.filter});
    }, [props.filter]);

    function applyFilter() {
        props.onChange && props.onChange(filter);
    }

    return <Row>
        <Col xs={"auto"}><strong>Филтър:</strong></Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Начална дата</Form.Label>
                <ReactDatePicker className={"form-control"}
                                 isClearable={false}
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     if (d) {
                                         d.setMinutes(0);
                                         d.setHours(0);
                                         d.setSeconds(0);
                                         d.setMilliseconds(0);
                                         setFilter(prevState => {
                                             prevState.fromDate = d
                                             return {...prevState};
                                         });
                                     }
                                 }}
                                 selected={filter.fromDate}
                                 maxDate={filter.toDate}
                />
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Крайна дата</Form.Label>
                <ReactDatePicker className={"form-control"}
                                 isClearable={false}
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     if (d) {
                                         d.setMinutes(59);
                                         d.setHours(23);
                                         d.setSeconds(59);
                                         d.setMilliseconds(999);
                                         setFilter(prevState => {
                                             prevState.toDate = d
                                             return {...prevState};
                                         });
                                     }
                                 }}
                                 selected={filter.toDate}
                                 minDate={filter.fromDate}
                />
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Продукт:</Form.Label>
                <SelectValueFromDialog value={filter.product?.name || ''}
                                       onChange={(product) => {
                                           setFilter(prevState => {
                                               return {...prevState, product: product}
                                           })
                                       }}
                                       dialog={productsDialog}/>
            </Form.Group>
        </Col>
        {
            props.viewMode === "out" &&
            <Col>
                <Form.Group className={"w-100"}>
                    <Form.Label>Клиент:</Form.Label>
                    <SelectValueFromDialog value={filter.client?.name || ''}
                                           onChange={(client) => {
                                               setFilter(prevState => {
                                                   return {...prevState, client: client}
                                               })
                                           }}
                                           dialog={clientsDialog}/>
                </Form.Group>
            </Col>
        }
        {props.viewMode === "in" &&
            <Col>
                <Form.Group className={"w-100"}>
                    <Form.Label>Доставчик:</Form.Label>
                    <SelectValueFromDialog value={filter.provider?.name || ''}
                                           onChange={(provider) => {
                                               setFilter(prevState => {
                                                   return {...prevState, provider: provider}
                                               })
                                           }}
                                           dialog={providerDialog}/>
                </Form.Group>
            </Col>
        }

        <Col xs={"auto"}>
            <div style={{paddingTop: "30px"}}>
                <CheckIconButton onClick={applyFilter}/>
            </div>
        </Col>
    </Row>
}

export default WarehouseInOutFilter;
