import {Card, Container, Tab, Tabs} from "react-bootstrap";
import {useState} from "react";
import moment from "moment";
import Lazy from "../../../common/Lazy";
import WarehouseTransactionsReport from "./WarehouseTransactionsReport";

export default function WarehouseReportsPage() {
    const [currentTab, setCurrentTab] = useState<string>("");

    return <Container>
        <Card>
            <Card.Header>
                <Card.Title>Склад - складово стопанство</Card.Title>
            </Card.Header>

            <Card.Body>
                <Tabs onSelect={eventKey => setCurrentTab(eventKey || "")} activeKey={currentTab}
                      className={"mb-1 ps-2"}>
                    <Tab title={"Справка операции"} eventKey={"transactions"}>
                        <Lazy visible={currentTab === "transactions"}>
                            <WarehouseTransactionsReport />
                        </Lazy>
                    </Tab>
                    <Tab title={"Справка доставки"} eventKey={"in"}>
                        <Lazy visible={currentTab === "in"}>
                            <WarehouseTransactionsReport />
                        </Lazy>
                    </Tab>
                    <Tab title={"Справка продажби"} eventKey={"out"}>
                        <Lazy visible={currentTab === "out"}>
                            <WarehouseTransactionsReport />
                        </Lazy>
                    </Tab>
                </Tabs>

                {
                    currentTab === "" && <div className={"m-5 p-5 text-center fw-bold"}>Моля изберете справка, за да продължите...</div>
                }

            </Card.Body>

        </Card>


    </Container>
}
