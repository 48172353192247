import {useState} from "react";

import {DocumentData, updateDoc, addDoc, collection, getFirestore} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";


export interface RegionsDetailsProps {
    doc: DocumentData | null;
    country: string,
    onDone: () => any;
}

export function RegionDetails({doc, country, onDone}: RegionsDetailsProps) {
    const [name, setName] = useState(doc?.data().name || '');
    const [saving, setSaving] = useState(false);

    const save = async () => {
        setSaving(true);

        if(doc) {
            await updateDoc(doc.ref, {name: name});
        } else {
            await addDoc(collection(fbDb, "regions"),
                {country: country, name: name});
        }
        setSaving(false);
        onDone();
    }

    return (
        <>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {doc ? "Корекция" : "Добавяне"} на регион
                </h5>
                <Form className={"p-2"}>
                    <Form.Group className="mb-3">
                        <Form.Label>Държава</Form.Label>
                        <Form.Control type="text" disabled={true} value={country}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Име на региона</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={save}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
