import {Button, CloseButton, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import ModalBackground from "../../../../common/ModalBackground";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import "./ExpeditionPlanPrintPreview.css";
import {ExpeditionCourseType} from "../../expedition-plan/ExpeditionCourse";
import {dateToString} from "../../../../common";
import {ExpeditionType} from "../../../../fb-converters/expedition-converter";

interface Props {
    zIndex?: number;
    expeditionCourse: ExpeditionCourseType;
    expeditions: ExpeditionType[];
    onDismiss: () => void;
}


export default function ExpeditionPlanPrintPreview({expeditionCourse, expeditions, zIndex, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);

    const printContent = (
        <Container fluid ref={componentRef} className={"p-5"}>
            {/*<div className={"d-none d-print-block m-5"}>&nbsp;</div>*/}
            <Row>
                <h2 className={"text-uppercase w-100 text-center"}>ПЛАН ЗА ЕКСПЕДИЦИЯ</h2>
            </Row>

            <Row className={"mt-5 mb-5"}>
                <Col xs={"auto"} className={"pe-5"}>
                    <Row>Дата:</Row>
                    <Row>Шофьор:</Row>
                    <Row>Кола:</Row>
                </Col>

                <Col>
                    <Row><b>{dateToString(expeditionCourse.expeditionDate)}</b></Row>
                    <Row><b>{expeditionCourse.driverName}</b></Row>
                    <Row><b>{expeditionCourse.carName}</b></Row>
                </Col>
            </Row>

            <Row>
                <Table className={"items"}>
                    <thead>
                    <tr>
                        <th className={"text-center"} rowSpan={2}>№</th>
                        <th className={"text-center"} rowSpan={2}>Запитване</th>
                        <th className={"text-center"} rowSpan={2}>Посока</th>
                        <th className={"text-center"} colSpan={3}>Клиент</th>
                        <th className={"text-center"} rowSpan={2}>Допълнителна информация</th>
                        <th className={"text-center"} colSpan={2}>Заминаване (кг)</th>
                        <th className={"text-center"} colSpan={2}>Връщане (кг)</th>
                        <th className={"text-center"} rowSpan={2}>Палети (зам)</th>
                        <th className={"text-center"} rowSpan={2}>Палети (вр)</th>
                    </tr>
                    <tr>
                        <th className={"text-center"}>Име</th>
                        <th className={"text-center"}>Адрес</th>
                        <th className={"text-center"}>Телефон</th>
                        <th className={"text-center"}>Пратка</th>
                        <th className={"text-center"}>Палет</th>
                        <th className={"text-center"}>Скрап</th>
                        <th className={"text-center"}>Доставка</th>
                    </tr>
                    </thead>
                    <tbody>
                    {expeditions.map((exp, index) => (
                        <tr key={index}>
                            <td className={"text-center"}>{index + 1}</td>
                            <td>{exp.inquiry?.inquiryNumber} / {dateToString(exp.inquiry?.inquiryDate)}</td>
                            <td>{exp.client?.region}</td>
                            <td>{exp.invoiceClientName}</td>
                            <td>{exp.client?.address}</td>
                            <td className={"text-nowrap"}>{exp.client?.phone}</td>
                            <td>{exp.notes}</td>
                            <td className={"text-center"}>{exp.weights?.leaving?.parcels}</td>
                            <td className={"text-center"}>{exp.weights?.leaving?.pallets}</td>
                            <td className={"text-center"}>{exp.weights?.arriving?.scrap}</td>
                            <td className={"text-center"}>{exp.weights?.arriving?.delivery}</td>
                            <td className={"text-center"}>{exp.palets?.leaving}</td>
                            <td className={"text-center"}>{exp.palets?.arriving}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>




            {/*    <Col className={"border rounded-1 p-2"}>*/}
            {/*        <Row>*/}
            {/*            <Col xs={5}>*/}
            {/*                <h5 className={"text-nowrap"}>Запитв.No:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{card.inquiryNumber?.toString().padStart(6, '0')}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Col xs={5}>*/}
            {/*                <h5>Дата:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{dateToString(card.inquiryDate)}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*    <Col className={"border rounded-1 p-2"}>*/}
            {/*        <Row>*/}
            {/*            <Col xs={5}>*/}
            {/*                <h5 className={"text-nowrap"}>Поръчка No:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{card.invoiceNumber.toString().padStart(6, '0')}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Col xs={5}>*/}
            {/*                <h5>Дата:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{dateToString(card.invoiceDate)}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*    <Col className={"border rounded-1 p-2"} xs={4}>*/}
            {/*        <Row>*/}
            {/*            <Col xs={7}>*/}
            {/*                <h5>Относно:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{card.regarding}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Col xs={"7"}>*/}
            {/*                <h5>Място на изв.:</h5>*/}
            {/*            </Col>*/}
            {/*            <Col className={"text-end"}>*/}
            {/*                <h5><b>{card.productionPlace}</b></h5>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row className={"mt-5"}>*/}
            {/*    <Col>*/}
            {/*        <h5><b>СПЕЦИФИКАЦИЯ:</b></h5>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <Table bordered>*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <th className={"text-center print-no-bg"}>№</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Тип на стоката</th>*/}
            {/*                <th className={"text-center print-no-bg"} colSpan={4}>Размери (L/B/H1/H2) mm</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Брой</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Конектори</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Тип на изводите</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Заряд</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Дренажни отв.</th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            {card.specification.map((spec, index) => (*/}
            {/*                <tr key={index}>*/}
            {/*                    <CenterAlignedCell>{index + 1}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productName}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productSize.l}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productSize.b}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productSize.h1}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productSize.h2}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.productQty}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.connectors}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.outputType}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.charging && <Form.Check checked={true}/>}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{spec.drenageHoles &&*/}
            {/*                        <Form.Check checked={true}/>}</CenterAlignedCell>*/}
            {/*                </tr>*/}
            {/*            ))}*/}
            {/*            </tbody>*/}
            {/*        </Table>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row className={"mt-5"}>*/}
            {/*    <Col>*/}
            {/*        <h5><b>ДОПЪЛНИТЕЛНИ ИЗИСКВАНИЯ:</b></h5>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <div className={"border border-2 rounded border-dark p-3"}>*/}
            {/*            {card.notes}*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row className={"mt-5"}>*/}
            {/*    <Col>*/}
            {/*        <h5><b>НЕОБХОДИМИ МАТЕРИАЛИ:</b></h5>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <Table bordered>*/}
            {/*            <thead>*/}
            {/*            <tr className={"print-no-bg"}>*/}
            {/*                <th className={"text-center print-no-bg"}>№</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Тип на стоката</th>*/}
            {/*                <th className={"text-center print-no-bg"} colSpan={4}>Размери (L/B/H1/H2) mm</th>*/}
            {/*                <th className={"text-center print-no-bg"}>К-во</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Ед.</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Доставчик</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Дата на доставка</th>*/}
            {/*                <th className={"text-center print-no-bg"}>Адрес на доставка</th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            {card.materials.map((material, index) => (*/}
            {/*                <tr key={index}>*/}
            {/*                    <CenterAlignedCell>{index + 1}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productName}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productSize.l}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productSize.b}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productSize.h1}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productSize.h2}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productQty}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.productDimension}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.supplier}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{dateToString(material.supplyDate)}</CenterAlignedCell>*/}
            {/*                    <CenterAlignedCell>{material.supplyAddress}</CenterAlignedCell>*/}
            {/*                </tr>*/}
            {/*            ))}*/}
            {/*            </tbody>*/}
            {/*        </Table>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row className={"mt-5"}>*/}
            {/*    <Col>*/}
            {/*        <h5><b>УСЛОВИЯ НА ДОСТАВКА КЪМ КЛИЕНТ:</b></h5>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col xs={"auto"}>*/}
            {/*        Транспорт: <b>{card.transport}</b>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        Адрес: <b>{card.address}</b>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        Лице за контакти: <b>{card.personForContact}</b>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        Телефон: <b>{card.phone}</b>*/}
            {/*    </Col>*/}
            {/*    <Col xs={"auto"} style={{paddingRight: "120px"}}>*/}
            {/*        Краен срок за доставка: <b>{dateToString(card.deliveryDate)}</b>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        Допълнителни изисквания: <b>{card.deliveryNote}</b>*/}
            {/*    </Col>*/}
            {/*</Row>*/}


            <div className="pagebreak"></div>


        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>Печат на документ</Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                        Печат....
                    </Button>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
