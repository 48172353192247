import React, {useState} from "react";
import {Link} from "react-router-dom";
import {API} from "../api";
import {UserNameComponent} from "./UserNameComponent";
import {Col, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {RegionsDialog} from "../pages/nomenclatures/regions/RegionsDialog";
import {ClientsDialog} from "../pages/nomenclatures/clients/ClientsDialog";
import {UsersDialog} from "../pages/nomenclatures/users/UsersDialog";
import {ProducersDialog} from "../pages/nomenclatures/producers/ProducersDialog";
import {ProvidersDialog} from "../pages/nomenclatures/providers/ProvidersDialog";
import {ProductsDialog} from "../pages/nomenclatures/products/ProductsDialog";
import {ServicesDialog} from "../pages/nomenclatures/services/ServicesDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faBank,
    faBatteryCar, faBuildingCircleCheck, faCalendar, faChartLine, faClipboardCheck,
    faCogs, faComments, faDolly, faFileInvoice,
    faGlobe,
    faHandshake,
    faIndustry, faList,
    faPersonDigging, faRobot,
    faTruck, faUsers, faUserTie, faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import {StandartsDialog} from "../pages/nomenclatures/standarts/StandartsDialog";
import {PositionsDialog} from "../pages/nomenclatures/positions/PositionsDialog";
import {CampaignsDialog} from "../pages/nomenclatures/campaigns/CampaignsDialog";
import {WarehouseObjectsDialog} from "../pages/nomenclatures/warehouse-objects/WarehouseObjectsDialog";
import {VirtualProductsDialog} from "../pages/nomenclatures/virtual-products/VirtualProductsDialog";
import {ProductionDialog} from "../pages/nomenclatures/production/ProductionDialog";
import {BankAccountsDialog} from "../pages/nomenclatures/bank-accounts/BankAccountsDialog";
import {CarsDialog} from "../pages/nomenclatures/cars/CarsDialog";

export function HeaderComponent(props: any) {
    const [selectedNav, setSelectedNav] = useState<string | null>(null);

    return (
        <>
            <Nav className="navbar navbar-light bg-light page-header"
                 onSelect={(selectedItem) => setSelectedNav(selectedItem)}
            >
                <Navbar.Brand className={"w-160px"}>
                    <Link to={"/"} className={"text-dark text-nowrap"}>
                        <img src={require('../assets/images/logo.png')} style={{height: "2em"}}/>
                    </Link>
                </Navbar.Brand>
                {
                    API.user &&
                    <>
                        <Row>
                            <Col>
                                <NavDropdown title="CRM" id="nav-dropdown" className={"fw-bold"}>
                                    <NavDropdown.Item>
                                        <Link to={"/crm"} className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faAddressBook}/>&nbsp;&nbsp;Календар
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Link to={"/crm/reports/activities"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faClipboardCheck}/>&nbsp;&nbsp;Справка за активности
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Customer Inquiries">
                                        <Link to={"/crm/customers-inquiries"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faComments}/>&nbsp;&nbsp;Запитвания и оферти
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Customer Invoices">
                                        <Link to={"/crm/customers-invoices"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faFileInvoice}/>&nbsp;&nbsp;Фактуриране
                                        </Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Col>

                            <Col>
                                <NavDropdown title="Склад" id="nav-dropdown" className={"fw-bold"}>
                                    <NavDropdown.Item eventKey="WAREHOUSESOUT">
                                        <Link to={"/warehouses/out-transactions"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;Склад - продажби
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSESIN">
                                        <Link to={"/warehouses/in-transactions"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;Склад - доставки
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSES_REQUESTS">
                                        <Link to={"/warehouses/requests"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faDolly}/>&nbsp;&nbsp;Склад - заявки
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSESREPORT">
                                        <Link to={"/warehouses/reports"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faChartLine}/>&nbsp;&nbsp;Справки
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="VIRTUAL_PRODUCTS">
                                        <FontAwesomeIcon icon={faList}/>&nbsp;&nbsp;
                                        Производствена номенклатура</NavDropdown.Item>

                                    <NavDropdown.Item eventKey="PRODUCTION">
                                        <FontAwesomeIcon icon={faRobot}/>&nbsp;&nbsp;
                                        Собствено производство</NavDropdown.Item>

                                    <NavDropdown.Item eventKey="Corporate Pricing">Интрастат</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSES">
                                        <Link to={"/warehouses"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faWarehouse}/>&nbsp;&nbsp;Складово стопанство
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSEREVISION">
                                        <Link to={"/warehouses-revisions"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faBuildingCircleCheck}/>&nbsp;&nbsp;Складови ревизии
                                        </Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Col>

                            <Col>
                                <NavDropdown title="Производство и експедиция" id="nav-dropdown" className={"fw-bold"}>
                                    <NavDropdown.Item eventKey="Production Plan">
                                        <Link to={"/production/plan"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faCalendar}/>&nbsp;&nbsp;Производствен план
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Дневен капацитет</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Отчитане на
                                        производство</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Натовареност на
                                        производство</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Expedition Plan">
                                        <Link to={"/expedition/plan"}
                                              className={"text-dark text-nowrap text-decoration-none"}>
                                            <FontAwesomeIcon icon={faCalendar}/>&nbsp;&nbsp;Дневен план за
                                            експедиция
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Документи за
                                        експедиция</NavDropdown.Item>
                                </NavDropdown>
                            </Col>

                            <Col>
                                <NavDropdown title="Търговци" id="nav-dropdown" className={"fw-bold"}>
                                    <NavDropdown.Item eventKey="Basic Pricing">Планиране на работен
                                        ден</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Командировки</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Отчитане</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Corporate Pricing">Бонусна система</NavDropdown.Item>
                                </NavDropdown>
                            </Col>

                            <Col>
                                <Nav.Item>
                                    <Nav.Link href="/home" className={"fw-bold"}>Поддръжка</Nav.Link>
                                </Nav.Item>
                            </Col>

                            <Col>
                                <Nav.Item>
                                    <Nav.Link href="/home" className={"fw-bold text-nowrap"}>Присъствен
                                        график</Nav.Link>
                                </Nav.Item>
                            </Col>

                            <Col>
                                <NavDropdown title="Номенклатури" id="nav-dropdown" className={"fw-bold"}
                                             color={"#000"} style={{color: "#000 !important"}}
                                >
                                    <NavDropdown.Item eventKey="REGIONS">
                                        <FontAwesomeIcon icon={faGlobe}/>&nbsp;&nbsp;Държави и
                                        региони</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="CLIENTS">
                                        <FontAwesomeIcon icon={faHandshake}/>&nbsp;&nbsp;Клиенти</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="PRODUCERS">
                                        <FontAwesomeIcon icon={faIndustry}/>&nbsp;&nbsp;Производители</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="PROVIDERS">
                                        <FontAwesomeIcon icon={faTruck}/>&nbsp;&nbsp;Доставчици</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="PRODUCTS">
                                        <FontAwesomeIcon icon={faBatteryCar}/>&nbsp;&nbsp;Продукти</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="SERVICES">
                                        <FontAwesomeIcon icon={faPersonDigging}/>&nbsp;&nbsp;Услуги</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="STANDARTS">
                                        <FontAwesomeIcon icon={faCogs}/>&nbsp;&nbsp;Стандарти
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="WAREHOUSE_OBJECTS">
                                        <FontAwesomeIcon icon={faWarehouse}/>&nbsp;&nbsp;Складови обекти
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="BANK_ACCOUNTS">
                                        <FontAwesomeIcon icon={faBank}/>&nbsp;&nbsp;Банкови сметки
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="CARS">
                                        <FontAwesomeIcon icon={faTruck}/>&nbsp;&nbsp;Превозни средства
                                    </NavDropdown.Item>
                                    {
                                        API.user.isAdmin &&
                                        <>
                                            <NavDropdown.Divider/>
                                            <NavDropdown.Item eventKey="USERS">
                                                <FontAwesomeIcon
                                                    icon={faUsers}/>&nbsp;&nbsp;Потребители</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="USER_POSITIONS">
                                                <FontAwesomeIcon
                                                    icon={faUserTie}/>&nbsp;&nbsp;Позиции</NavDropdown.Item>
                                            <NavDropdown.Divider/>
                                            <NavDropdown.Item eventKey="CAMPAIGN">
                                                <FontAwesomeIcon
                                                    icon={faAddressBook}/>&nbsp;&nbsp;Кампании</NavDropdown.Item>
                                        </>
                                    }
                                </NavDropdown>
                            </Col>

                        </Row>


                        <Navbar.Text className={"col-auto"}>
                            <UserNameComponent/>
                        </Navbar.Text>
                    </>
                }
            </Nav>

            {(selectedNav === "REGIONS") &&
                <RegionsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "CLIENTS") &&
                <ClientsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "USERS") && <UsersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "PRODUCERS") &&
                <ProducersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "PROVIDERS") &&
                <ProvidersDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "PRODUCTS") &&
                <ProductsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "SERVICES") &&
                <ServicesDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "STANDARTS") &&
                <StandartsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "WAREHOUSE_OBJECTS") &&
                <WarehouseObjectsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "BANK_ACCOUNTS") &&
                <BankAccountsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "CARS") &&
                <CarsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "USER_POSITIONS") &&
                <PositionsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "CAMPAIGN") &&
                <CampaignsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "VIRTUAL_PRODUCTS") &&
                <VirtualProductsDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
            {(selectedNav === "PRODUCTION") &&
                <ProductionDialog zIndex={1100} show={true} onClose={() => setSelectedNav('')}/>}
        </>
    )
}
