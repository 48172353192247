import React, {useMemo} from 'react';
import {Page, Text, View, Document, Font, Image as PdfImage} from '@react-pdf/renderer';
import pageHeaderBg from '../../../../../../assets/images/PageHeaderBG.png';
import pageFooterBg from '../../../../../../assets/images/PageFooterBG.png';
import {styles} from './OfferPdfStyles';
import {CustomerInquiryType} from "../../../../../../fb-converters/cutomerInquiry-converter";
import {CustomerOfferType} from "../../../../../../fb-converters/cutomerOffer-converter";
import {dateToString} from "../../../../../../common";
import OfferProductPdfTable from './product-table/OfferProductPdfTable';
import OfferServicesPdfTable from "./services-table/OfferServicesPdfTable";
import OfferAttrPdfTable from "./attr-table/OfferAttrPdfTable";
import OfferInvestmentPdfTable from "./investment-table/OfferInvestmentPdfTable";
import OfferPdfDeliveryConditions from "./OfferPdfDeliveryConditions";
import OfferPdfStaticPage1 from "./OfferPdfStaticPage1";
import OfferPdfStaticPage2 from "./OfferPdfStaticPage2";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../../../../global-state/global-db";
import {calculateOfferSummary} from "../../../CustomerOfferDetailsSummary";

Font.register({
    family: "Roboto",
    src: "/OpenSans-Regular.ttf",
    fonts: [
        {
            src: '/OpenSans-Regular.ttf',
        },
        {
            src: '/OpenSans-Bold.ttf',
            fontWeight: 'bold',
        }
    ]
});


export const HeaderLine = (props: any) => (
    <View style={styles.headerLine} wrap={false}>
        <View style={{flexDirection: "row"}}>
            <Text>{props.text1}</Text>
            <Text style={{fontWeight: "bold"}}>{props.textBold}</Text>
        </View>
        <View style={{flexDirection: "row"}}>
            <Text>{props.text2}</Text>
            <Text style={{fontWeight: "bold"}}>{props.text2Bold}</Text>
        </View>
    </View>
);


interface OfferPdfDocumentProps {
    inquiry: CustomerInquiryType;
    offerToPrint: CustomerOfferType;
}

const OfferPdfDocument = ({inquiry, offerToPrint}: OfferPdfDocumentProps) => {

    const products = useHookstate(appDb.products);
    const summary = useMemo(() => calculateOfferSummary(offerToPrint, products), [offerToPrint, products]);

    const offer = {...offerToPrint};
    if(offer.bestProductPriceIdx && offer.bestProductPriceIdx > 0) {
        offer.products.includes(offer.products[offer.bestProductPriceIdx]) && offer.products.unshift(offer.products.splice(offer.bestProductPriceIdx, 1)[0]);
        offer.bestProductPriceIdx = 0;
    }

    return (
        <Document>
            <Page
                style={styles.body}
                orientation="landscape"
                size="A4"
            >

                <PdfImage
                    src={pageHeaderBg}
                    style={styles.headerImage}
                    fixed
                />


                <HeaderLine
                    text1={`Оферта: `}
                    textBold={`${inquiry.inquiryNumber} - ${offer.offerNumber}`}
                    text2={`ДАТА: `}
                    text2Bold={dateToString(offer.offerDate)}
                />

                <HeaderLine
                    text1={`ДО: `}
                    textBold={inquiry.client?.name}
                />

                <HeaderLine
                    text1={`НА ВНИМАНИЕТО НА: `}
                    textBold={inquiry.location?.mol}
                />

                <HeaderLine
                    text1={`УВАЖАЕМИ/А: `}
                    textBold={inquiry.location?.mol}
                />

                <View style={[styles.card, {fontWeight: "bold", fontSize: 10, marginTop: 15}]}>
                    <Text>
                        ИМАМЕ УДОВОЛСТВИЕТО ДА ПРЕДСТАВИМ НА ВАШЕТО ВНИМАНИЕ СЛЕДНАТА ОФЕРТА:
                    </Text>
                </View>

                {
                    offer.products?.map((product, index) => (
                        <View style={styles.card}>
                            <OfferProductPdfTable key={index} offer={offer} productIndex={index}/>
                            {
                                product.services?.length > 0 && <View style={{marginTop: 5}}>
                                    <OfferServicesPdfTable services={product.services} productQty={product.qty}/>
                                </View>
                            }
                            {
                                product.attributes?.length > 0 && <View style={{marginTop: 5}}>
                                    <OfferAttrPdfTable attributes={product.attributes} productQty={product.qty}/>
                                </View>
                            }
                        </View>))
                }

                {
                    summary.length > 0 &&
                    <View style={styles.card} wrap={false}>
                        <View style={{marginBottom: 5}}>
                            <Text style={{fontWeight: "bold"}}>
                                Калкулация на месечната инвестиция за рамките на гаранционния срок:
                            </Text>
                        </View>
                        <OfferInvestmentPdfTable offer={offer} summary={summary}/>
                    </View>
                }

                <OfferPdfDeliveryConditions
                    validityPeriod={offer.validityPeriod ?? ""}
                    paymentMethod={offer.paymentMethod ?? ""}
                    comment={offer.comment ?? ""}
                />

                <OfferPdfStaticPage1/>

                <OfferPdfStaticPage2/>

                <PdfImage
                    src={pageFooterBg}
                    style={styles.footerImage}
                    fixed
                />
                <Text style={styles.pageNumber}
                      render={({pageNumber, totalPages}) => (
                          `${pageNumber} / ${totalPages}`
                      )} fixed/>
            </Page>
        </Document>
    );
}

export default OfferPdfDocument;
