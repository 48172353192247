import {CrmPeriodProps} from "./CrmCalendar";
import {Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {Activity} from "../../types";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, doc, DocumentData, getFirestore, orderBy, query, updateDoc, where} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../App";
import moment from "moment";
import {useEffect, useState} from "react";
import {API} from "../../api";
import CrmActivityListItem from "./CrmActivityListItem";
import CrmPastActivitiesViewer from "./CrmPastActivitiesViewer";


export default function CrmPeriodActivitesViewer({
                                                     period,
                                                     changePeriodType,
                                                     onSetMoveActivitiesCallback
                                                 }: CrmPeriodProps) {
    const [working, setWorking] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

    const _periodDate = moment().year(period.year)
        .month((period.month || 0))
        .date(period.day || moment().date())
        .hour(0).minutes(0).seconds(0).milliseconds(0);

    const [rows, setRows] = useState<Array<any>>([]);

    const _date = _periodDate.toDate();
    const _date1 = _periodDate.add(1, "day").toDate();

    const [activities, loadActivities, errorActivities] = useCollection(
        query(collection(fbDb, "activities"),
            where('userId', '==', API.user?.id || "undefined"),
            where("date", '>=', _date),
            where("date", '<', _date1),
            orderBy("date")
        ));

    console.log("CrmPeriodActivitesViewer", activities);

    const onMoveActivities = (userId: string) => {
        setWorking(true);
        selectedActivities.forEach(
            async aId => {
                let docRef = doc(fbDb, `activities/${aId}`);
                if(docRef) {
                    await updateDoc(docRef, {userId: userId});
                }
            }
        );
        setSelectedActivities([]);
        setIsAllChecked(false);
        setWorking(false);
    }

    useEffect(() => {
        if (isAllChecked && activities) {
            setSelectedActivities(activities.docs.map(d => d.id));
        } else setSelectedActivities([]);
    }, [isAllChecked, activities]);

    useEffect(() => {
        if (onSetMoveActivitiesCallback) {
            onSetMoveActivitiesCallback(selectedActivities.length > 0 ? onMoveActivities : null);
        }
    }, [selectedActivities]);

    function toggleSelection(id: string) {
        let _selection = [...selectedActivities];
        let idx = _selection.indexOf(id);
        if (idx === -1) {
            _selection.push(id)
        } else {
            _selection.splice(idx, 1);
        }

        setSelectedActivities([..._selection]);
    }

    useEffect(() => {
        if (activities) {
            const _rows: any[] = [];
            activities.docs.forEach(
                d => {
                    const _data = d.data();
                    _rows.push(
                        <CrmActivityListItem key={d.id} data={_data}
                                             isSelected={selectedActivities.indexOf(_data.id) > -1}
                                             onToggle={() => toggleSelection(_data.id)}
                                             onDetails={() => showActivityDetails(d.id, _data)}/>
                    );
                }
            );
            setRows(_rows);
        }
    }, [activities, selectedActivities])

    function showActivityDetails(documentId: string, _data: DocumentData) {
        let activity = {
            id: documentId,
            activityType: _data.activityType,
            withPriority: _data.withPriority,
            date: _data.date,
            duration: _data.duration,
            clientId: _data.clientId,
            activityStatus: _data.activityStatus,
            pospondDate: _data.pospondDate,
            pospondActivityId: _data.pospondActivityId,
            userId: _data.userId,
            campaignId: _data.campaignId,
            details: _data.details
        } as Activity;

        changePeriodType("activity", {...period, activity: activity});
    }

    console.log("CrmPeriodActivitesViewer", period, activities, selectedActivities);
    return <>
        <Row>
            <Col>
                <Table size={"sm"} bordered hover>
                    <thead>
                    <tr>
                        <th className={"w-30px"}></th>
                        <th>Дата/час</th>
                        <th>Кампания</th>
                        <th>Регион</th>
                        <th>Клиент</th>
                        <th>Тип</th>
                        <th>Статус</th>
                        <th>
                            <Form.Check type={"checkbox"} checked={isAllChecked}
                                        onChange={() => setIsAllChecked(!isAllChecked)}
                            />
                        </th>
                        <th/>
                    </tr>
                    </thead>

                    <tbody>
                    <CrmPastActivitiesViewer
                        viewDate={_date}
                        onDetails={d => showActivityDetails(d.id, d.data())}
                        onToggle={docId => toggleSelection(docId)}
                        selectedActivities={selectedActivities}/>
                    {!working && rows}
                    </tbody>

                </Table>
                {
                    working &&
                    <Row>
                        <Col className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    </>
}
