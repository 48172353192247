import {CustomerOfferType} from "../../../../../fb-converters/cutomerOffer-converter";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {CustomerInquiryType} from "../../../../../fb-converters/cutomerInquiry-converter";
import './CustomerOfferPrintPreview.css';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import CustomPrintSendEmail, {
    FileAttachmentType
} from "../../../../../common/custom-print-elements/CustomPrintSendEmail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import OfferPdfDocument from "./offer-pdf/OfferPdfDocument";
import {pdf, PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import {API} from "../../../../../api";


interface Props {
    inquiry: CustomerInquiryType;
    offer: CustomerOfferType;
    onDone: () => void;
}

const CustomerOfferPrintPreview = ({inquiry, offer, onDone}: Props) => {
    const [working, setWorking] = useState(false);
    const [pdfBlob, setPdfBlob] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const ids = ["page1", "page2", "page3"]; // list id component want to export

    const handleExport = useCallback(async () => {

        const pdf = new jsPDF("l", "pt", "a4");
        let allHeight = 0;

        const canvasPromises = ids.map(async (id) => {
            const element = document.getElementById(id);
            const canvas = await html2canvas(element!);
            const img = canvas.toDataURL("image/png");
            const imgProperties = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;

            if (allHeight > 700) {  // heigth over a4 heigth add page for pdf
                allHeight = 5;
                pdf.addPage();
            }

            pdf.addImage(
                img,
                "PNG",
                0,
                allHeight,
                pdfWidth - 5,
                pdfHeight,
                id,
                "FAST",
            );

            allHeight += pdfHeight + 5;
        });
        const dataField = await Promise.all(canvasPromises).then(
            (res) => res,
        );

        pdf.save(`title-${new Date().toLocaleString()}.pdf`);
    }, [ids]);


    const handleGerenatePdf = async () => {
        setWorking(true);
        handleExport();
        setWorking(false);
    };

    const emailHTML = useMemo(() => {
        let emailHTML = "<div>Здравейте,</div>" +
            "<div>&nbsp;</div>" +
            "<div>Прикачен е файл с оферта.</div>" +
            "<div>&nbsp;</div>" +
            "<div>&nbsp;</div>" +
            "<div>Поздрави,</div>" +
            "<div>&nbsp;</div>" +
            "<div>" + API.user?.name + "</div>" +
            "<div>" + API.user?.email + "</div>";
        return emailHTML;
    }, [inquiry, offer]);

    const PdfOfferDocument = useMemo(() => {
        return <OfferPdfDocument
            inquiry={inquiry!}
            offerToPrint={offer!}
        />
    }, [inquiry, offer]);

    const pdfDownloadLink = useMemo(() => {
        return <PDFDownloadLink
            document={PdfOfferDocument}
            fileName={`Offer-${inquiry.inquiryNumber} - ${offer.offerNumber}.pdf`}>
            {({ blob, url, loading, error }) => {
                if(!loading && blob) {
                    blob.arrayBuffer().then((buffer) => {
                        var base64 = btoa(
                            new Uint8Array(buffer)
                                .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                        setPdfBlob(base64);
                    });
                }
                setLoading(loading);
                return loading ? '' : '';
            }}
        </PDFDownloadLink>
    }, [inquiry, offer]);

    return (
        <>
            <Modal.Body>
                <div className={"w-100 max-h-75vh overflow-hidden"} style={{height: "90vh"}}>
                    {
                        loading && <div className={"text-center"} style={{ paddingTop: "33vh"}}><Spinner animation={"border"}/></div>
                    }
                    <PDFViewer
                        width={"100%"} height={"100%"}
                        showToolbar={true}
                    >
                        { PdfOfferDocument }
                    </PDFViewer>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                <Col xs={4}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                    </Button>
                                </Col>
                                <Col/>
                                <Col xs={"auto"} className={"text-end"}>
                                    {
                                        pdfBlob &&
                                    <CustomPrintSendEmail
                                        subject={`Оферта от Индустриални батерии АД`}
                                        email={emailHTML}
                                        attachments={
                                            {
                                                filename: `Оферта ${inquiry.inquiryNumber} - ${offer.offerNumber}.pdf`,
                                                blob: pdfBlob,
                                            } as FileAttachmentType
                                        }
                                        initialSendToEmail={inquiry.location?.email ?? ""}/>
                                    }
                                </Col>
                                {/*<Col xs={"auto"} className={"text-end"}>*/}
                                {/*    <Button onClick={handlePrint} variant={"info"} className={"text-light"}>*/}
                                {/*        <FontAwesomeIcon icon={faPrint}/> Печат*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                                <Col>
                                    { pdfDownloadLink }
                                </Col>
                            </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
        ;
}

export default CustomerOfferPrintPreview;
