import {FC, useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import {ClientsDialog} from "../../../nomenclatures/clients/ClientsDialog";
import {ClientType} from "../../../nomenclatures/clients/hooks/useClients";
import {CampaignsDialog} from "../../../nomenclatures/campaigns/CampaignsDialog";
import {UsersDialog} from "../../../nomenclatures/users/UsersDialog";
import {UserType} from "../../../../fb-converters/users-converter";
import CheckIconButton from "../../../../common/icon-buttons/CheckIconButton";
import {ActivityStatuses} from "../../../../types";

export interface CrmReportActivitiesFilterType {
    fromDate?: Date | null;
    toDate?: Date | null;
    campaign?: any;
    client?: ClientType;
    user?: UserType;
    status?: string;
}

interface Props {
    filter: CrmReportActivitiesFilterType;
    onChange: (filter: CrmReportActivitiesFilterType) => void;
}

const CrmReportActivitiesFilter: FC<Props> = (props) => {
    const [filter, setFilter] = useState<CrmReportActivitiesFilterType>({});
    const campaignsDialog = <CampaignsDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const usersDialog = <UsersDialog show={true} zIndex={1060} onClose={() => {
    }}/>

    useEffect(() => {
        setFilter({...props.filter});
    }, [props.filter]);

    function applyFilter() {
        props.onChange && props.onChange(filter);
    }

    return <Row>
        <Col xs={"auto"}><strong>Филтър:</strong></Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Начална дата</Form.Label>
                <ReactDatePicker className={"form-control"} isClearable
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     setFilter(prevState => {
                                         prevState.fromDate = d
                                         return prevState;
                                     })
                                 }}
                                 selected={filter.fromDate}
                />
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Крайна дата</Form.Label>
                <ReactDatePicker className={"form-control"} isClearable
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     {
                                         setFilter(prevState => {
                                             prevState.toDate = d
                                             return prevState;
                                         })
                                     }
                                 }}
                                 selected={filter.toDate}
                />
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Кампания:</Form.Label>
                <SelectValueFromDialog value={filter.campaign?.data()?.name || ''}
                                       onChange={(campaign) => {
                                           setFilter(prevState => {
                                               return {...prevState, campaign: campaign}
                                           })
                                       }}
                                       dialog={campaignsDialog}/>
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Клиент:</Form.Label>
                <SelectValueFromDialog value={filter.client?.name || ''}
                                       onChange={(client) => {
                                           setFilter(prevState => {
                                               return {...prevState, client: client}
                                           })
                                       }}
                                       dialog={clientsDialog}/>
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Търговски представител:</Form.Label>
                <SelectValueFromDialog value={filter.user?.name || ''}
                                       onChange={(user) => {
                                           setFilter(prevState => {
                                               return {...prevState, user: user}
                                           })
                                       }}
                                       dialog={usersDialog}/>
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <Form.Label>Статус:</Form.Label>
            <Form.Group className={"w-100"}>
                <select className={"form-select"} name="activityStatus"
                        onChange={e => setFilter(prevState => {
                            return {...prevState, status: e.target.value}
                        })}
                        defaultValue={filter.status}>
                    {
                        ActivityStatuses.map(item => <option key={item}
                                                             value={item}>{item}</option>)
                    }
                </select>
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <div style={{paddingTop: "30px"}}>
                <CheckIconButton onClick={applyFilter}/>
            </div>
        </Col>
    </Row>
}

export default CrmReportActivitiesFilter;
