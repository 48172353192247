import {Col, Row} from "react-bootstrap";
import BoldElement from "../../common/BoldElement";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEquals,
    faRightFromBracket,
    faRightLeft,
    faRightToBracket
} from "@fortawesome/free-solid-svg-icons";
import {API} from "../../api";

export default function CrmStatisticsViewer(props: any) {
    const _user = API.user ? "_" + API.user?.id : "";

    const data = props.data || {};
    const _in = data[`и${_user}`] || 0;
    const _out = data[`в${_user}`] || 0;
    const _plan = (data[`с${_user}`] || 0) + (data[`з${_user}`] || 0) + (data[`н${_user}`] || 0);
    const _total = _plan + _in + _out;

    return _total == 0 ? null : (
        <Row className={_total == 0 ? "opacity-50" : ""}>
            {
                _out > 0 &&
                <Col className={"text-success"}>
                    <FontAwesomeIcon
                        icon={faRightToBracket}/>&nbsp;&nbsp;&nbsp;&nbsp;Входящи: <BoldElement>{_out}</BoldElement>
                </Col>
            }
            {
                _in > 0 &&
                <Col className={"text-primary"}>
                    <FontAwesomeIcon
                        icon={faRightFromBracket}/>&nbsp;&nbsp;&nbsp;&nbsp;Изходящи: <BoldElement>{_in}</BoldElement>
                </Col>
            }
            {
                _plan > 0 &&
                <Col className={"text-secondary"}>
                    <FontAwesomeIcon icon={faRightLeft}/>&nbsp;&nbsp;Други: <BoldElement>{_plan}</BoldElement>
                </Col>
            }
            <Col>
                <FontAwesomeIcon icon={faEquals} />&nbsp;&nbsp;Общо: <BoldElement>{_total}</BoldElement>
            </Col>
        </Row>
    )
}
