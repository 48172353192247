import {FC, useEffect, useState} from "react";
import {
    useWarehouseRequestsRepo, warehouseRequestConverter
} from "../pages/warehouse/warehouse-transactions/warehouse-request/useWarehouseRequestsRepo";
import useFirebaseCollection from "../hooks/useFirebaseCollection";
import {collection, query, where} from "firebase/firestore";
import {WarehouseRequestType} from "../pages/warehouse/warehouse-transactions/warehouse-request/WarehouseRequestType";
import {convertWarehouseItemData} from "../pages/warehouse/hooks/useWarehouseItemsForObject";
import {Spinner} from "react-bootstrap";
import {WarehouseItemType} from "../pages/warehouse/hooks/useWarehouseInItems";
import {dateToString} from "../common";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../App";
import {productionCardConverter, ProductionCardType} from "../pages/production/types/ProductionCardType";

interface ProductAvailabilityProps {
    productId: string;
}

const ProductAvailability: FC<ProductAvailabilityProps> = ({productId}) => {
    const [requestDocs, requestsLoading, requestError] =
        useFirebaseCollection("warehouseRequests",
            [
                where("isActive", "==", true),
                where("itemIds", "array-contains", productId)
            ]);

    const [warehouseDocs, warehouseLoading, wharehouseError] =
        useFirebaseCollection("warehouseItems",
            [
                where("productId", "==", productId)
            ]);

    const [productionCardData, loadingProductionCard, errorProductionCard] = useCollection(
        query(collection(fbDb, "productionCards"),
            where("materialIds", "array-contains", productId),
            where("productionStatus", "in", ["В производство", "Изпълнена"]),
            where("cardDate", ">=", new Date(2024, 8, 1, 0, 0, 0))
        ).withConverter(productionCardConverter)
    );

    const [reserved, setReserved] = useState<string>("");
    const [reservedDetails, setReservedDetails] = useState<string[]>([]);
    const [availability, setAvailability] = useState<string>("");
    const [requested, setRequested] = useState<string>("");
    const [availabilityDetails, setAvailabilityDetails] = useState<string[]>([]);

    console.log(productionCardData, loadingProductionCard, errorProductionCard);

    useEffect(() => {
        if (!requestsLoading && !requestError && requestDocs
            && !warehouseLoading && !wharehouseError && warehouseDocs
            && !loadingProductionCard && !errorProductionCard && productionCardData
        ) {
            const _requestDocs = requestDocs.docs.map(d => warehouseRequestConverter.fromFirestore(d));
            let qty = 0;
            let details: string[] = [];
            _requestDocs.forEach(d => {
                d.items.forEach(i => {
                    if (i.product.id === productId) {
                        qty += i.qty;
                        details.push(`${d.requestNumber?.toString().padStart(4, "0")}/${dateToString(i.expectedDeliveryDate) || "???"} - ${i.qty}`);
                    }
                });
            });
            if (qty > 0) {
                setRequested("Заявени: " + qty.toString() + "; ");
                setAvailabilityDetails(details);
            } else {
                setRequested("");
                setAvailabilityDetails([]);
            }

            let reservedQty = 0;
            const _reservedDetails: string[] = [];
            const _productionCardData = productionCardData.docs.map(d => d.data() as ProductionCardType);
            _productionCardData.forEach(d => {
                d.materials.forEach(m => {
                    if (m.productId === productId) {
                        reservedQty += m.productQty;
                        _reservedDetails.push(`${d.cardNumber}/${dateToString(d.cardDate)} - ${m.productQty}`);
                    }
                });
            });
            if (reservedQty > 0) {
                setReserved("Резервирани: " + reservedQty.toString() + "; ");
                setReservedDetails(_reservedDetails);
            } else {
                setReserved("");
            }

            let availableQty = 0;
            const _warehouseDocs = warehouseDocs.docs.map(d => d.data() as WarehouseItemType);
            _warehouseDocs.forEach(d => {
                availableQty += d.availableQty ?? 0
            });
            availableQty -= reservedQty;
            if (availableQty !== 0) {
                setAvailability("Налични: " + availableQty.toString() + "; ");
            } else {
                setAvailability("");
            }

        }
    }, [requestDocs, warehouseDocs]);

    if (requestsLoading || warehouseLoading) {
        return <Spinner animation="border" variant="primary"/>;
    }

    console.log("ProductAvailability", availability, requested, reserved, availabilityDetails);

    return (
        availability.trim() + requested.trim() + reserved.trim() === "" ?
            <span>Няма наличност</span>
            :
            <>
                {
                    reserved && <span className={"text-danger"}>{reserved}<br/></span>
                }
                {
                    reservedDetails.map(
                        d => <span key={d} className={" text-danger fst-italic"}>{d}<br/></span>)
                }
                {availability}
                {
                    requested &&
                    <>
                        <span className={"text-info"}><br/>{requested}</span>
                        <span className={"fst-italic text-info"}>
                    {
                        availabilityDetails.map(
                            d => <span key={d}><br/>{d}</span>
                        )
                    }
            </span>
                    </>
                }
            </>
    );
};

export default ProductAvailability;
