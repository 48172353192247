import {updateDoc, addDoc, collection, getFirestore, doc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, showErrorsListInToast, textToFloat} from "../../../common";
import {ProductDimens} from "./ProductDimensesList";
import {useState} from "react";


export interface ProductDimensDetailsProps {
    zIndex?: number;
    dimens: ProductDimens | null;
    onDone: () => any;
}

export function ProductDimensDetails({
                                 zIndex,
                                 dimens,
                                 onDone
                             }: ProductDimensDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        const dimensesRef = collection(fbDb, "productDimenses");
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (dimens?.id) {
            await updateDoc(doc(dimensesRef, dimens?.id), _data);
        } else {
            await addDoc(dimensesRef, _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: any = {
            name: event.target.elements.name.value || '',
        };

        if(data.name || data.name.length > 0) {
            save(data);
        } else {
            showErrorsListInToast("Внимание", ["Моля, въведете мерна единица и опитайте пак."]);
        }
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {dimens ? "Корекция" : "Добавяне"} на мерна единица
                </h5>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={dimens?.name}/>
                        </Form.Group>
                    </Col>

                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
