import {CrmPeriodProps} from "./CrmCalendar";
import {Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {useEffect, useState} from "react";
import {Activity, ActivityStatuses, ActivityTypes} from "../../types";
import SelectValueFromDialog from "../../common/SelectValueFromDialog";
import {ClientsDialog} from "../nomenclatures/clients/ClientsDialog";
import OptionalElement from "../../common/OptionalElement";
import BoldElement from "../../common/BoldElement";
import NoWrapCol from "../../common/NoWrapCol";
import {
    FirestoreError,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDocs,
    query,
    setDoc,
    where
} from "firebase/firestore";
import {fbDb,} from "../../App";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {API} from "../../api";
import {firebaseDateToDate, removeEmptyFields, showErrorsListInToast, textToInt} from "../../common";
import useClientsLocations from "../nomenclatures/clients/hooks/useClientLocations";
import {ClientType} from "../nomenclatures/clients/hooks/useClients";
import {ClientLocationType, locationConverter} from "../../fb-converters/location-converter";
import {useDocument} from "react-firebase-hooks/firestore";


function ClientViewDetailsRow(props: { client: ClientType | ClientLocationType | undefined }) {
    const {client} = props;
    if (!client) {
        return null;
    }

    const sizeGroup = typeof client.sizeGroup === 'string' ?
        client.sizeGroup : client.sizeGroup?.name || "";

    return (
        <Row className={"border m-1 mt-0 p-1 small bg-secondary-light"}>
            <OptionalElement isVisible={client?.region}>
                <NoWrapCol>Регион: <BoldElement>{client?.region || ''}</BoldElement> </NoWrapCol>
            </OptionalElement>
            <OptionalElement isVisible={client?.address}>
                <NoWrapCol>Адрес: <BoldElement>{client?.address || ''}</BoldElement> </NoWrapCol>
            </OptionalElement>
            <OptionalElement isVisible={client?.mol}>
                <NoWrapCol>МОЛ: <BoldElement>{client?.mol || ''}</BoldElement> </NoWrapCol>
            </OptionalElement>
            <OptionalElement isVisible={client?.sizeGroup}>
                <NoWrapCol>Група: <BoldElement>{sizeGroup}</BoldElement> </NoWrapCol>
            </OptionalElement>
        </Row>
    );
}

export default function ActivityDetails({period, periodType, changePeriodType}: CrmPeriodProps) {
    const [saving, setSaving] = useState(false);
    const [confirmDelete, setconfirmDelete] = useState(false);
    const [editActivity, setEditActivity] = useState<Activity>(() => {
        const _act = {
            ...period.activity!,
            date: firebaseDateToDate(period.activity?.date),
            pospondDate: firebaseDateToDate(period.activity?.pospondDate)
        };
        return _act;
    });
    const [client, setClient] = useState<ClientLocationType | undefined>(undefined);
    const [cCampaigns, setCCampaigns] = useState<Array<any>>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>(period.activity?.activityStatus || "");
    const [pospondDate, setPospondDate] = useState<Date>(firebaseDateToDate(period.activity?.pospondDate) || new Date());

    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {
    }}/>

    const _date = moment().year(period.year).month(period.month || 0).day(period.day || 1);

    useEffect(() => {
        const q = query(collection(fbDb, "campaigns"),
            where("toDate", ">", editActivity.date || new Date())
        );
        getDocs(q).then(
            result => {
                let _seconds = _date.valueOf() / 1000;
                let _cmp: Array<any> = [];

                result.docs.forEach(c => {
                    if (c.data().fromDate.seconds <= _seconds) _cmp.push(c)
                })

                setCCampaigns(_cmp);
            }
        );
    }, [editActivity.date]);

    useEffect(() => {
        if (!editActivity.date) {
            setEditActivity({...editActivity, date: _date.toDate()});
        }
    }, []);

    async function removePospondActivity(id: string) {
        let q = query(collection(fbDb, "activities"),
            where('postpondAtivityId', '==', id)
        );

        let _docs: any = await getDocs(q);
        _docs.docs.forEach(
            async (d: any) => {
                await deleteDoc(d.ref)
            }
        );
    }

    async function updateClientActivityCount(campaignId: string, clientId: string | null) {
        try {
            const docCount = await getCountFromServer(query(collection(fbDb, "activities"),
                where("campaignId", "==", campaignId),
                where("clientId", "==", clientId),
                where("activityStatus", "in", ["приет", "отказано/отменено"]),
            ));

            setDoc(doc(fbDb, `campaigns/${campaignId}/user_activities/${clientId}`), {count: docCount.data().count}).then(
                () => setDoc(doc(fbDb, `campaigns/${campaignId}/user_activities/change_trigger`), {datetime: new Date()})
            );
        } catch (e: unknown) {
            showErrorsListInToast("Грешка", [(e as FirestoreError).message])
        }
    }

    const [clientLocation, loadClientLocation, errorClientLocation] =
        useDocument(doc(fbDb, `clientLocations/${editActivity?.clientId}`).withConverter(locationConverter));

    useEffect(() => {
        if (clientLocation && clientLocation.exists()) {
            setClient(clientLocation.data() as ClientLocationType);
        }
    }, [clientLocation]);

    console.log(editActivity, client);

    const save = async (data: Activity) => {
        setSaving(true);
        let _data = removeEmptyFields<Activity>(data);

        if (!_data) return;

        _data.clientId = client?.id || null;
        _data.clientName = client?.clientName || client?.name || null;

        if (editActivity.activityStatus !== "отложен") {
            _data.pospondDate = null;
        } else {
            _data.pospondDate = pospondDate;
        }

        if (_data.id) {
            await setDoc(doc(fbDb, 'activities', _data.id), _data);
            await removePospondActivity(_data.id);
        } else {
            let newDoc = await addDoc(collection(fbDb, "activities"), _data);
            _data.id = newDoc.id;
        }

        if (editActivity.activityStatus === "отложен") {
            await addDoc(collection(fbDb, "activities"), {
                ..._data,
                date: pospondDate, pospondDate: null, postpondAtivityId: _data.id, activityStatus: null
            });
        }

        if (data.campaignId) {
            updateClientActivityCount(data.campaignId, data.clientId);
        }

        changePeriodType(periodType, {...period, activity: undefined});
        setSaving(false);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        editActivity.duration = textToInt(event.target.elements.duration.value)?.toString() || '0';
        editActivity.activityStatus = event.target.elements.activityStatus.value || "";
        editActivity.activityType = event.target.elements.activityType.value || null;
        editActivity.clientId = client?.id || null;
        editActivity.clientName = client?.name || null;
        editActivity.region = client?.region || null;
        editActivity.campaignName = "";
        cCampaigns?.forEach(
            d => {
                if (d.id == editActivity.campaignId) editActivity.campaignName = d.data().name;
            }
        )
        editActivity.userId = API.user?.id || undefined;
        editActivity.userName = API.user?.name;
        editActivity.details = event.target.elements.details.value || null;
        save(editActivity);
    }

    async function removeActivity() {
        setSaving(true);
        const _doc = await doc(fbDb, 'activities', editActivity.id!);
        await deleteDoc(_doc);

        if (editActivity.campaignId) {
            updateClientActivityCount(editActivity.campaignId, editActivity.clientId);
        }

        setSaving(false);

        changePeriodType("activity", {...period, activity: undefined});
    }

    return <>
        <Form className={"p-2"} onSubmit={handleSubmit}>
            <Table>
                <thead>
                <tr>
                    <th colSpan={4}>
                        Детайли за активност
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={"w-140px"}>
                        Дата:
                    </td>
                    <td>
                        <Form.Group className={"w-140px"}>
                            <ReactDatePicker className={"form-control"} isClearable
                                             dateFormat="dd.MM.yyyy HH:mm"
                                             onChange={(d) => {
                                                 setEditActivity({...editActivity, date: d || new Date()})
                                             }}
                                             selected={editActivity.date}
                                             timeCaption={"Час"}
                                             timeIntervals={15}
                                             timeFormat="p"
                                             showTimeSelect
                            />
                        </Form.Group>
                    </td>
                    <td className={"text-end"}>
                        Времетраене:
                    </td>
                    <td>
                        <Form.Group className={"w-140px"}>
                            <Form.Control type={"number"} name="duration"
                                          defaultValue={(editActivity.duration === '0') ? '' : editActivity.duration}/>
                        </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td>Кампания:</td>
                    <td colSpan={3}>
                        <Form.Group className={"w-100"}>
                            <select className={"form-select"} name="campaignId"
                                    onChange={e => {
                                        setEditActivity({...editActivity, campaignId: e.target.value})
                                    }}
                                    value={editActivity.campaignId}>
                                <option></option>
                                {
                                    cCampaigns.map(
                                        c => <option key={c.id} value={c.id}> {c.data().name || ''}  </option>
                                    )
                                }
                            </select>
                        </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td>
                        Клиент:
                    </td>
                    <td colSpan={3}>
                        <Row>
                            <Col>
                                <Form.Group className={"w-100"}>
                                    <SelectValueFromDialog value={client?.name || ''}
                                                           onLocationChange={(clientLocation) => {
                                                               setClient(clientLocation.location)
                                                           }}
                                                           dialog={clientsDialog}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <ClientViewDetailsRow client={client}/>
                    </td>
                </tr>
                <tr>
                    <td>Тип активност:</td>
                    <td>
                        <Row className={"align-items-center"}>
                            <Col>
                                <Form.Group className={"w-100"}>
                                    <select className={"form-select"} name="activityType"
                                            defaultValue={editActivity.activityType}>
                                        {
                                            ActivityTypes.map(item => <option key={item} value={item}>{item}</option>)
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Check
                                    type={"checkbox"}
                                    label={"с приоритет"}
                                    checked={editActivity.withPriority}
                                    onClick={() => setEditActivity({
                                        ...editActivity,
                                        withPriority: !editActivity.withPriority
                                    })}
                                />
                            </Col>
                        </Row>
                    </td>
                    <td className={"w-75px text-end"}>Статус:</td>
                    <td>
                        <Row>
                            <Col>
                                <Form.Group className={"w-100"}>
                                    <select className={"form-select"} name="activityStatus"
                                            onChange={e => setSelectedStatus(e.target.value)}
                                            defaultValue={editActivity.activityStatus}>
                                        {
                                            ActivityStatuses.map(item => <option key={item}
                                                                                 value={item}>{item}</option>)
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            {
                                selectedStatus === "отложен" &&
                                <Col xs={"auto"}>
                                    <Form.Group className={"w-140px"}>
                                        <ReactDatePicker className={"form-control"}
                                                         dateFormat="dd.MM.yyyy HH:mm"
                                                         onChange={(d) => {
                                                             setPospondDate(d || new Date())
                                                         }}
                                                         selected={pospondDate}
                                                         timeCaption={"Час"}
                                                         timeIntervals={15}
                                                         timeFormat="p"
                                                         showTimeSelect
                                        />
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td>Детайли:</td>
                    <td colSpan={3}>
                        <Form.Group>
                            <Form.Control as={"textarea"} name="details" defaultValue={editActivity.details}/>
                        </Form.Group>
                    </td>
                </tr>

                <tr>
                    <td colSpan={4}>
                        <Row>
                            <Col>
                                {
                                    !saving && <Button variant={"secondary"}
                                                       onClick={e => changePeriodType(periodType, {
                                                           ...period,
                                                           activity: undefined
                                                       })}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> Към календара
                                    </Button>
                                }
                            </Col>
                            <Col className={"text-center"} xs={"auto"}>
                                {
                                    saving ? <Spinner animation={"border"}/>
                                        : <>
                                            {
                                                editActivity.id && !confirmDelete &&
                                                <Button variant={"danger"} onClick={e => setconfirmDelete(true)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                            }
                                            {
                                                confirmDelete &&
                                                <span className={"ms-2"}>
                                    Сигурни ли сте?&nbsp;
                                                    <Button variant={"success"}
                                                            onClick={e => setconfirmDelete(false)}>Не</Button>&nbsp;
                                                    <Button variant={"danger"} onClick={removeActivity}>Да</Button>&nbsp;
                                </span>
                                            }
                                            <Button variant={"primary"} type={"submit"} className={"float-end ms-2"}>
                                                <FontAwesomeIcon icon={faSave}/> Запази
                                            </Button>
                                        </>
                                }
                            </Col>
                        </Row>

                    </td>
                </tr>
                </tbody>
            </Table>
        </Form>
    </>;
}
