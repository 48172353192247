import {Button, Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import EditableTableCell from "../../../common/EditableTableCell";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {textToFloat, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas} from "../../../common";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import ListIconButton from "../../../common/icon-buttons/ListIconButton";
import React, {useState} from "react";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {
    CustomerOfferType, productToOfferMapper
} from "../../../fb-converters/cutomerOffer-converter";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import ProductAvailability from "../../../common/ProductAvailability";

interface Props {
    zIndex?: number;
    discount_price: number;
    offer: CustomerOfferType;
    setOffer: (offer: CustomerOfferType) => void;
    bestProductPriceIdx: number | null;
    setBestProductPriceIdx: (idx: number | null) => void;
}


export function ProductDimens(props: { product: ProductType }) {
    let text = "";
    if (props.product._length) text += props.product._length + " / ";
    if (props.product.width) text += props.product.width + " / ";
    if (props.product.height1) text += props.product.height1 + " / ";
    if (props.product.height2) text += props.product.height2;

    return text ? <>{text}</> : null;
}

export default function CustomerOfferDetailsProducts({
                                                         zIndex,
                                                         discount_price,
                                                         offer,
                                                         setOffer,
                                                         bestProductPriceIdx,
                                                         setBestProductPriceIdx
                                                     }: Props) {
    const _products = useHookstate(appDb.products);
    const [editCell, setEditCell] = useState("");
    const [showProductsDialog, setShowProductsDialog] = useState(false);

    return (
        <>
            <Row>
                <Col xs={"12"}>Продукти</Col>
                <Col xs={"12"}>
                    <Table>
                        <thead>
                        <tr>
                            <RightAlignedCell header rowSpan={2}>No:</RightAlignedCell>
                            <th rowSpan={2}>Тип</th>
                            <RightAlignedCell header className={"w-120px"} rowSpan={2}>Срок
                                дост<br/>(раб.дни)</RightAlignedCell>
                            <CenterAlignedCell header colSpan={4}><br/>Габаритни размери</CenterAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Гаранция<br/>(месеци)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Живот<br/>(цикли)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Ед.цена<br/>(лева)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Отстъпка<br/>(%)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Цена след<br/>отстъпка, (лв)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Цена за<br/>скрап, (лв)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Крайна цена<br/>(лв)</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Кол.</RightAlignedCell>
                            <RightAlignedCell header rowSpan={2}>Цена<br/>(лв)</RightAlignedCell>
                            <RightAlignedCell header className={"text-end w-100px"} rowSpan={2}/>
                        </tr>
                        <tr>
                            <CenterAlignedCell header className={"w-40px"}>L</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>B</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>H1</CenterAlignedCell>
                            <CenterAlignedCell header className={"w-40px"}>H2</CenterAlignedCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            offer?.products?.map(
                                (p, idx) => {
                                    const _product = _products.find(value => value.value.id === p.product.id);
                                    const price = (p.singlePrice || 0);
                                    const discount = p.discount || 0;
                                    const finalPrice = price - (price * discount / 100);

                                    return <tr key={`${p.product.id}_${idx}`}>
                                        <td> {idx + 1} </td>
                                        <td> {p.product.name} <br/> <ProductAvailability productId={p.product.id} /> </td>
                                        <EditableTableCell inEditMode={true} // editCell === `${idx}-deliveryDays`}
                                                           className={"w-120px text-end"}
                                                           isWorking={false}
                                                           value={p.deliveryDays}
                                                           onEdit={() => setEditCell(`${idx}-deliveryDays`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].deliveryDays = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-length`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product._length?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-length`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product._length = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>
                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-width`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.width?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-width`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.width = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>
                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-height1`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.height1?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-height1`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.height1 = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-height2`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.height2?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-height2`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.height2 = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>


                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-warranty`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.warranty?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-warranty`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.warranty = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-liveCycles`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.product.liveCycles?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-liveCycles`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].product.liveCycles = newValue;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-price`}
                                                           className={"w-120px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.singlePrice.toString())}
                                                           onEdit={() => setEditCell(`${idx}-price`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].singlePrice = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <EditableTableCell inEditMode={true}//editCell === `${idx}-discount`}
                                                           className={"w-120px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.discount.toString())}
                                                           onEdit={() => setEditCell(`${idx}-discount`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].discount = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>


                                        <RightAlignedCell> {zeroToEmptyStringAsCurrency(finalPrice.toString())} </RightAlignedCell>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-skrubprice`}
                                                           className={"w-120px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.skrubPrice.toString())}
                                                           onEdit={() => setEditCell(`${idx}-skrubprice`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].skrubPrice = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <RightAlignedCell> {
                                            zeroToEmptyStringAsCurrencyWithCommas((finalPrice - p.skrubPrice).toString())
                                        } </RightAlignedCell>

                                        <EditableTableCell inEditMode={true} //editCell === `${idx}-qty`}
                                                           className={"w-60px text-end"}
                                                           isWorking={false}
                                                           value={zeroToEmptyStringAsCurrency(p.qty?.toString())}
                                                           onEdit={() => setEditCell(`${idx}-qty`)}
                                                           onBlur={newValue => {
                                                               const _offer = {
                                                                   ...offer,
                                                                   products: [...offer.products]
                                                               };
                                                               _offer.products[idx].qty = textToFloat(newValue) || 0;
                                                               setOffer(_offer);
                                                               setEditCell("");
                                                           }}
                                                           onDone={() => setEditCell("")}/>

                                        <RightAlignedCell> {
                                            zeroToEmptyStringAsCurrencyWithCommas(((finalPrice - p.skrubPrice) * p.qty).toString())
                                        } </RightAlignedCell>

                                        <RightAlignedCell>
                                            {
                                                editCell === `${idx}-remove` ?
                                                    <>
                                                        <CheckIconButton onClick={() => {
                                                            const _offer = {
                                                                ...offer,
                                                                products: [...offer.products]
                                                            };
                                                            _offer.products.splice(idx, 1);
                                                            setEditCell("");
                                                            setOffer(_offer);
                                                        }}/>
                                                        <CancelIconButton onClick={() => setEditCell("")}/>
                                                    </> :
                                                    <>
                                                        <Button
                                                            variant={(bestProductPriceIdx === idx) ? "warning" : "secondary"}
                                                            title={"Продукт с най-добра цена"} size={"sm"}
                                                            onClick={() => {
                                                                setBestProductPriceIdx(idx === bestProductPriceIdx ? null : idx);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faStar}/>
                                                        </Button>&nbsp;
                                                        <TrashIconButton size={"sm"}
                                                                         onClick={() => setEditCell(`${idx}-remove`)}/>
                                                    </>
                                            }
                                        </RightAlignedCell>
                                    </tr>
                                }
                            )
                        }
                        <tr>
                            <RightAlignedCell colSpan={17}>
                                <ListIconButton onClick={() => setShowProductsDialog(true)} variant={"primary"}/>
                            </RightAlignedCell>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <ProductsDialog show={showProductsDialog} zIndex={(zIndex || 1060) + 10}
                            onClose={() => setShowProductsDialog(false)}
                            onSelect={result => {
                                if (result) {
                                    const product = {...result} as ProductType;
                                    const products = [...offer.products];
                                    const productOffer = productToOfferMapper(product, discount_price, offer.eurExchangeRate || 1);
                                    if (productOffer) {
                                        products.push(productOffer);
                                    }
                                    setOffer({...offer, products: products});
                                    setShowProductsDialog(false);
                                }
                            }}
                            onMultiSelect={results => {
                                const products = [...offer.products];
                                results.forEach((result: any) => {
                                    const product = {...result} as ProductType;
                                    const productOffer = productToOfferMapper(product, discount_price, offer.eurExchangeRate || 1);
                                    if (productOffer) {
                                        products.push(productOffer);
                                    }
                                });
                                setOffer({...offer, products: products});
                                setShowProductsDialog(false);
                            }}
            />
        </>
    )
}
