import {Button, Card, Col, Container, Modal, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import CustomerInquiryDetails from "./CustomerInquiryDetails";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {OwnCompanyType, ownCopanyConverter} from "../../../fb-converters/own-company-converter";
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {fbDb} from "../../../App";
import CustomerInquiryList from "./CustomerInquiryList";
import CustomerInquiryFiles from "./CustomerInquiryFiles";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {dateToString} from "../../../common";
import MonthYearTab from "../../../common/MonthYearTab";

export default function CustomersInquiriesPage(props: {
    client?: ClientType | null,
    onDone?: VoidFunction,
}) {
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [inquiry, setInquiry] = useState<CustomerInquiryType | undefined>();
    const [inquiryFiles, setInquiryFiles] = useState<CustomerInquiryType | undefined>();
    const [ownCompanies, setOwnCompanies] = useState<OwnCompanyType[]>([]);

    function changeMonthYear(month: number, year: number) {
        setSelectedMonth(month);
        setSelectedYear(year);
    }

    async function loadOwnCompanies() {
        const ownCompaniesData = await getDocs(query(collection(fbDb, "ownCompanies"),
            orderBy("listIndex", "asc")).withConverter(ownCopanyConverter));
        setOwnCompanies(ownCompaniesData.docs.map(d => d.data()))
    }

    useEffect(() => {
        loadOwnCompanies();
    }, []);

    function addNewCustomerInquiry() {
        setInquiry({
            inquiryContent: "",
            inquiryNumber: 1,
            inquiryProducts: [],
            ownCompany: (ownCompanies && ownCompanies[0]) || null,
            services: [],
            fileUrls: [],
            additionalInfo: "",
            client: props.client || null,
            location: null,
            inquiryDate: new Date(),
            bestPriceProductIndex: null,
        });
    }

    return <>
        {
            props.client ?
                <>
                    <Modal.Body>
                        {
                            selectedMonth >= 0 && selectedYear &&
                            <CustomerInquiryList
                                forMonth={selectedMonth}
                                forYear={selectedYear}
                                client={props.client || undefined}
                                onEdit={inquiry => {
                                    setInquiry(inquiry)
                                }}
                                onEditFiles={inquiry => {
                                    setInquiryFiles(inquiry)
                                }}
                            />
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={"w-100"}>
                            <Col xs={6}>
                                {
                                    props.onDone &&
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        props.onDone && props.onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                    </Button>
                                }
                            </Col>
                            <Col className={"text-end"}>
                                <Button onClick={addNewCustomerInquiry}>
                                    <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;Добави ново
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </>
                :
                <Row>
                    <Col/>
                    <Col xs={8}>
                        <Card>
                            <Card.Header>
                                <Card.Title>CRM - Запитвания от клиенти</Card.Title>
                            </Card.Header>


                            <Card.Body className={"bg-secondary-light max-h-75vh d-block overflow-auto"}>
                                <MonthYearTab month={selectedMonth} year={selectedYear} onChange={changeMonthYear}/>
                                {
                                    selectedMonth >= 0 && selectedYear &&
                                    <CustomerInquiryList
                                        forMonth={selectedMonth}
                                        forYear={selectedYear}
                                        onEdit={inquiry => {
                                            setInquiry(inquiry)
                                        }}
                                        onEditFiles={inquiry => {
                                            setInquiryFiles(inquiry)
                                        }}
                                    />
                                }
                            </Card.Body>

                            <Card.Footer className={"text-end"}>
                                <Button onClick={addNewCustomerInquiry}>
                                    <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;Добави ново
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col/>
                </Row>

        }

        {
            inquiry &&
            <CustomerInquiryDetails inquiry={inquiry} ownCompanies={ownCompanies}
                                    onClose={() => setInquiry(undefined)}
            />
        }

        {
            inquiryFiles &&
            <CustomerInquiryFiles inquiry={inquiryFiles} onClose={() => setInquiryFiles(undefined)}
            />
        }
    </>
}
