import {
    DocumentData,
    DocumentReference,
    FirestoreError,
    QueryConstraint,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";


export interface WarehouseItemType {
    id?: string;
    ref?: DocumentReference;
    warehouseId: string;
    itemId?: string;
    productId: string;
    isOwnProduction: boolean;
    transactionId?: string;
    transactionDate?: Date;
    sku?: string | null;
    name: string;
    dimens?: string | null;
    qty?: number | null;
    singlePrice?: number | null;
    deliveryPrice?: number | null;
    minQty?: number | null;
    usedQty?: number | null;
    availableQty?: number | null;
}
export type WarehouseItemsType = WarehouseItemType[];

export function convertWarehouseInItemData(_objects: QueryDocumentSnapshot<DocumentData>[]): WarehouseItemsType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    warehouseId: d.get('warehouseId'),
                    itemId: d.get('itemId'),
                    productId: d.get('productId'),
                    isOwnProduction: d.get('isOwnProduction') || false,
                    transactionId: d.get('transactionId'),
                    transactionDate: d.get('transactionDate').toDate(),
                    name: d.get('name'),
                    sku: d.get('sku'),
                    dimens: d.get('dimens'),
                    qty: d.get('qty'),
                    singlePrice: d.get('singlePrice'),
                    deliveryPrice: d.get('deliveryPrice'),
                    usedQty: d.get('usedQty') || 0,
                    availableQty: d.get('availableQty'),
                } as WarehouseItemType;
            }
        );
    } else return [];
}


export default function UseWarehouseInItems(inId?: string, constraints?: QueryConstraint[]): [WarehouseItemsType, boolean, FirestoreError | undefined] {
    const finalConstraints = constraints || [];
    if(inId) {
        finalConstraints.push(where("transactionId", "==", inId));
    }

    const [documents, loading, error] = useFirebaseCollection("warehouseInItems", finalConstraints);
    const [convertedData, setConvertedData] = useState<WarehouseItemsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertWarehouseInItemData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
