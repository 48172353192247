import React, {FC, useEffect, useState} from "react";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {ARRAY_MONTHS_SHORT} from "../common";

type MonthYearTabProps = {
    month?: number;
    year?: number;
    onChange: (month: number, year: number) => void;
}

const MonthYearTab: FC<MonthYearTabProps> = ({month, year, onChange}) => {
    const toDayYear = new Date().getFullYear();
    const toDayMonth = new Date().getMonth();

    const [currentYear, setCurrentYear] = useState(year ?? toDayYear);
    const [currentMonth, setCurrentMonth] = useState(month ?? toDayMonth);
    const years = Array.from({length: 10}, (v, k) => toDayYear - 9 + k)
        .filter(y => y > 2021)
        .reverse();

    useEffect(() => {
        onChange(currentMonth, currentYear);
    }, [currentMonth, currentYear]);

    return (
        <Form.Group>
            <Row>
                <Col className={"ps-4"} xs={"auto"}>
                    <Tabs activeKey={currentYear.toString()} onSelect={
                        (k: string | null) => {
                            setCurrentYear(parseInt(k ?? currentYear.toString()));
                        }}>
                        {years.map(y => <Tab eventKey={y.toString()} title={y.toString()}/>)}
                    </Tabs>
                </Col>
                <Col />
                <Col xs={"auto"}>
                    <Tabs
                        variant={"pills"}
                        activeKey={currentMonth.toString()} onSelect={
                        k => {
                            setCurrentMonth(parseInt(k ?? currentMonth.toString()));
                        }}>
                        {ARRAY_MONTHS_SHORT.map((m, index) => <Tab eventKey={index.toString()} title={m}/>)}
                    </Tabs>
                </Col>

            </Row>
        </Form.Group>
    )
}

export default MonthYearTab;
