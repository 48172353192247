import {ClientsType} from "../../pages/nomenclatures/clients/hooks/useClients";
import {ClientLocationsType} from "../../fb-converters/location-converter";
import {ProductsType} from "../../pages/nomenclatures/products/hooks/useProductsOnce";
import {ProductionsType} from "../../pages/nomenclatures/production/hooks/useProductionOnce";
import {VirtualProductsType} from "../../pages/nomenclatures/virtual-products/hooks/useVirtualProductsOnce";

export type GlobalDbType = {
    clients: ClientsType;
    clientsLocations: ClientLocationsType;
    products: ProductsType;
    virtualProducts: VirtualProductsType;
    production: ProductionsType;
};

export const globalDbDefault: GlobalDbType = {
    clients: [],
    clientsLocations: [],
    production: [],
    products: [],
    virtualProducts: [],
}
