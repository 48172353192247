import {dateToString, showErrorsListInToast, zeroToEmptyString} from "../../../common";
import React, {FC, useMemo, useState} from "react";
import RightAlignedCell from "../../../common/RightAlignedCell";
import PrintIconButton from "../../../common/icon-buttons/PrintIconButton";
import {ExpeditionType} from "../../../fb-converters/expedition-converter";
import ExpeditionItem from "./ExpeditionItem";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import MinusIconButton from "../../../common/icon-buttons/MinusIconButton";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import {deleteDoc, doc, FirestoreError} from "firebase/firestore";
import {fbDb} from "../../../App";
import ExpeditionPlanPrintPreview from "../print/expedition/ExpeditionPlanPrintPreview";

export type ExpeditionCourseType = {
    id: string;
    expeditionDate: Date;
    driverId: string;
    driverName: string;
    carId: string;
    carName: string;
    totalQuantity: number;
    totalParcels: number;
    totalPallets: number;
    totalScrap: number;
    totalDelivery: number;
    totalPaletsLeaving: number;
    totalPaletsArriving: number;
    viewStatus: "active" | "completed";
}

type ExpeditionCourseProps = {
    zIndex: number;
    course: ExpeditionCourseType;
    expeditions: ExpeditionType[];
    onPrint: () => void;
}

const ExpeditionCourse: FC<ExpeditionCourseProps> = ({zIndex, course, expeditions, onPrint}) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [deleting, setDeleting] = useState(false);

    const filteredExpeditions = useMemo(() => expeditions.filter(e =>
        dateToString(e.expeditionDate) === dateToString(course.expeditionDate) &&
        e.driver?.id === course.driverId &&
        e.car?.id === course.carId), [course, expeditions]);

    function onDelete() {
        setDeleting(true);
        const promises = filteredExpeditions.map(e => e.id!)
            .map(id => {
                return new Promise<void>((resolve, reject) => {
                    // delete
                    deleteDoc(doc(fbDb, "expedition", id)).then(() => {
                        resolve();
                    }).catch((e) => {
                        const error = e as FirestoreError;
                        showErrorsListInToast("Грешка при изтриване на експедиция", [error.message]);
                        reject();
                    });
                });
            });
        Promise.all(promises).finally(
            () => {
                setDeleting(false);
            }
        )
    }

    return (<>
        <tr className={"fw-bold"}>
            <td className={"bg-secondary-light"}>
                {
                    !isExpanded && <DeleteConfirmIconButton onClick={onDelete} size={"sm"} />
                }&nbsp;
                        <PrintIconButton onClick={() => onPrint()} size={"sm"}/>
            </td>
            <td className={"bg-secondary-light"}>{dateToString(course.expeditionDate)}</td>
            <td className={"bg-secondary-light"}>{course.driverName}</td>
            <td className={"bg-secondary-light"}>{course.carName}</td>
            <td colSpan={7} className={"bg-secondary-light"}></td>
            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalQuantity)}</RightAlignedCell>


            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalParcels)}.</RightAlignedCell>
            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalPallets)}</RightAlignedCell>
            <RightAlignedCell className={"bg-secondary-light"}>
                {zeroToEmptyString(course.totalParcels + course.totalPallets)}..
            </RightAlignedCell>


            <RightAlignedCell className={"bg-secondary-light"}>{zeroToEmptyString(course.totalScrap)}</RightAlignedCell>
            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalDelivery)}</RightAlignedCell>
            <RightAlignedCell className={"bg-secondary-light"}>
                {zeroToEmptyString(course.totalScrap + course.totalDelivery)}
            </RightAlignedCell>

            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalPaletsLeaving)}</RightAlignedCell>
            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalPaletsArriving)}</RightAlignedCell>
            <RightAlignedCell
                className={"bg-secondary-light"}>{zeroToEmptyString(course.totalPaletsLeaving + course.totalPaletsArriving)}</RightAlignedCell>
            <CenterAlignedCell className={"bg-secondary-light"}>
                {
                    isExpanded ?
                        <MinusIconButton onClick={() => setIsExpanded(false)} size={"sm"}/>
                        : <PlusIconButton onClick={() => setIsExpanded(true)} size={"sm"}/>
                }
            </CenterAlignedCell>
        </tr>
        {
            isExpanded &&
            filteredExpeditions.map(e => <ExpeditionItem key={e.id} expedition={e}/>)
        }
        <tr>
            <td colSpan={99} className={"bg-dark"}>
            </td>
        </tr>
    </>);
}

export default ExpeditionCourse;
