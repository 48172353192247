import {CustomerInvoiceItemsType, CustomerInvoiceItemType} from "../../../fb-converters/cutomerInvoice-converter";
import {Dropdown} from "react-bootstrap";
import {useState} from "react";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import {ServicesDialog} from "../../nomenclatures/services/ServicesDialog";

export default function CustomerInvoiceDetailsSelectItem(props: {
    availableItems: CustomerInvoiceItemsType,
    currencyIndex: number,
    onSelectItem: (item: CustomerInvoiceItemType) => void
}) {

    const [selectFrom, setSelectFrom] = useState("");

    return <>
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" size={"sm"}/>

            <Dropdown.Menu>
                {
                    props.availableItems.map(
                        (i, idx) =>
                            <Dropdown.Item key={idx}
                                           onClick={event => {
                                               props.onSelectItem({...i, total: 0});
                                               setSelectFrom("");
                                           }}>
                                { i.name }
                            </Dropdown.Item>
                    )
                }
                <Dropdown.Item onClick={event => setSelectFrom("products")}>Изберете от списъка с
                    продукти</Dropdown.Item>
                <Dropdown.Item onClick={event => setSelectFrom("services")}>Изберете от списъка с услуги</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

        {
            selectFrom === "products" &&
            <ProductsDialog zIndex={1650}
                            show={true}
                            onClose={() => setSelectFrom("") }
                            onSelect={result => {
                                const _newItem: CustomerInvoiceItemType = {
                                    listIdx: -1,
                                    productId: result.id,
                                    code: result.sku || "",
                                    name: result.name,
                                    dimension: result.dimens || "брой",
                                    _length: result.length || 0,
                                    width: result.width || 0,
                                    height1: result.height1 || 0,
                                    height2: result.height2 || 0,
                                    qty: 1,
                                    price: (result.price || 0) * props.currencyIndex,
                                    discount: 0,
                                    total: 0,
                                    weight: result.weightWithElectrolit || 0,
                                    ownProduction: false,
                                }
                                props.onSelectItem(_newItem);
                                setSelectFrom("");
                            }} />
        }

        {
            selectFrom === "services" &&
            <ServicesDialog zIndex={1650}
                            show={true}
                            onClose={() => setSelectFrom("") }
                            onSelect={result => {
                                console.log(props.currencyIndex);
                                const _newItem: CustomerInvoiceItemType = {
                                    listIdx: -1,
                                    productId: null,
                                    code: "",
                                    name: result.name,
                                    dimension: "услуга",
                                    _length: 0,
                                    width: 0,
                                    height1: 0,
                                    height2: 0,
                                    qty: 1,
                                    price: (result.price || 0) * props.currencyIndex,
                                    discount: 0,
                                    total: 0,
                                    weight: 0,
                                    ownProduction: false,
                                }
                                props.onSelectItem(_newItem);
                                setSelectFrom("");
                            }} />
        }
    </>;
}
