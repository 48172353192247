import {ExpeditionType} from "../../../fb-converters/expedition-converter";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {dateToString, showErrorsListInToast, zeroToEmptyString} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import React, {FC, useState} from "react";
import {deleteDoc, doc, FirestoreError, getDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import EditIconButton from "../../../common/icon-buttons/EditIconButton";
import ExpeditionDetails from "./ExpeditionDetails";
import {productionCardConverter, ProductionCardExpeditionType, ProductionCardType} from "../types/ProductionCardType";

type ExpeditionItemProps = {
    expedition: ExpeditionType;
}

const ExpeditionItem: FC<ExpeditionItemProps> = ({expedition}) => {
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);


    function onEdit() {
        setEditing(true);
    }

    function onDelete() {
        setDeleting(true);
        // delete
        deleteDoc(doc(fbDb, "expedition", expedition.id!)).then(() => {
            setDeleting(false);
        }).catch((e) => {
            const error = e as FirestoreError;
            showErrorsListInToast("Грешка при изтриване на експедиция", [error.message]);
            setDeleting(false);
        });
    }

    async function saveExpedition(expedition: ExpeditionType) {
        setSaving(true);
        if (expedition.id) {
            // update
            try {
                if (expedition.cardId) {
                    const productionCardRef =
                        doc(fbDb, "productionCards", expedition.cardId).withConverter(productionCardConverter);
                    const productionCardSnap = await getDoc(productionCardRef);
                    if (productionCardSnap.exists()) {
                        const productionCard = productionCardSnap.data()!;
                        const productionCardExpedition: ProductionCardExpeditionType = {
                            expeditionId: expedition.id!,
                            expeditionDate: expedition.expeditionDate,
                            driverName: expedition.driver?.name ?? null,
                            carName: expedition.car?.name ?? null,
                            qty: expedition.productQty ?? 0
                        };

                        const expeditions = productionCard.expeditions ?? [];
                        const existingExpeditionIndex = expeditions.findIndex(e => e.expeditionId === expedition.id);
                        if (existingExpeditionIndex >= 0) {
                            expeditions[existingExpeditionIndex] = productionCardExpedition;
                        } else {
                            expeditions.push(productionCardExpedition);
                        }
                        productionCard.expeditions = expeditions;

                        const totalExpQty = expeditions.reduce((acc, e) => acc + (e.qty ?? 0), 0);
                        const totalCardQty = productionCard.specification[0]?.productQty ?? 0;
                        if (totalExpQty > totalCardQty) {
                            showErrorsListInToast("Грешка при запазване на експедиция", ["Общото количество на експедициите надвишава планираното количество за производство"]);
                            setSaving(false);
                            return;
                        }
                        if (totalExpQty === totalCardQty) {
                            productionCard.productionStatus = "Приключена";
                        } else {
                            if (productionCard.productionStatus === "Приключена") {
                                productionCard.productionStatus = "Изпълнена";
                            }
                        }

                        await updateDoc(productionCardRef, productionCard);
                    }
                }
                await updateDoc(doc(fbDb, "expedition", expedition.id), expedition);
            } catch (e) {
                const error = e as FirestoreError;
                showErrorsListInToast("Грешка при запазване на експедиция", [error.message]);
            } finally {
                setSaving(false);
            }
        }
        setEditing(false);
    }

    function setExpeditionStatus(value: string) {
        setSaving(true);
        updateDoc(doc(fbDb, "expedition", expedition.id!), {
            status: value
        }).then(() => {
        }).catch((e) => {
            const error = e as FirestoreError;
            showErrorsListInToast("Грешка при запазване на експедиция", [error.message]);
        }).finally(() => {
            setSaving(false);
        });
    }

    return (
        <>
            <tr key={expedition.id}>
                <td>
                    {
                        deleting ? <Spinner animation={"border"} size={"sm"}/> :
                            <>
                                <EditIconButton onClick={onEdit}/>&nbsp;
                                <DeleteConfirmIconButton onClick={onDelete} size={"sm"}/>
                            </>
                    }
                </td>
                <CenterAlignedCell>{dateToString(expedition.expeditionDate)}</CenterAlignedCell>
                <td>{expedition.driver?.name}</td>
                <td>{expedition.car?.name} {expedition.car?.regPlate}</td>
                <CenterAlignedCell>{expedition.inquiry?.inquiryNumber}</CenterAlignedCell>
                <td>{expedition.client?.name}</td>
                <td>{expedition.client?.region}</td>
                <td>{expedition.client?.address}</td>
                <td>{expedition.client?.contactPerson}</td>
                <td>{expedition.client?.phone}</td>
                <td>{expedition.notes}</td>
                <RightAlignedCell>{zeroToEmptyString(expedition.productQty)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.leaving?.parcels)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.leaving?.pallets)}</RightAlignedCell>
                <RightAlignedCell className={"fw-bold"}>{zeroToEmptyString(
                    (expedition.weights?.leaving?.parcels ?? 0) +
                    (expedition.weights?.leaving?.pallets ?? 0)
                )}</RightAlignedCell>

                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.arriving?.scrap)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.weights?.arriving?.delivery)}</RightAlignedCell>
                <RightAlignedCell className={"fw-bold"}>{zeroToEmptyString(
                    (expedition.weights?.arriving?.scrap ?? 0) +
                    (expedition.weights?.arriving?.delivery ?? 0)
                )}</RightAlignedCell>

                <RightAlignedCell>{zeroToEmptyString(expedition.palets?.leaving)}</RightAlignedCell>
                <RightAlignedCell>{zeroToEmptyString(expedition.palets?.arriving)}</RightAlignedCell>
                <RightAlignedCell className={"fw-bold"}>{zeroToEmptyString(
                    (expedition.palets?.leaving ?? 0) + (expedition.palets?.arriving ?? 0)
                )}</RightAlignedCell>
                <CenterAlignedCell>
                    {saving ? <Spinner animation={"border"} size={"sm"}/> :
                        <Form.Control
                            as={"select"}
                            value={expedition.status ?? undefined}
                            onChange={(e) => {
                                setExpeditionStatus(e.target.value);
                            }}
                        >
                            <option value={"ПЛАНИРАНА"}>ПЛАНИРАНА</option>
                            <option value={"ИЗПЪЛНЯВА СЕ"}>ИЗПЪЛНЯВА СЕ</option>
                            <option value={"ЗАВЪРШЕНА"}>ЗАВЪРШЕНА</option>
                            <option value={"ОТКАЗАНА"}>ОТКАЗАНА</option>
                        </Form.Control>
                    }
                </CenterAlignedCell>
            </tr>
            {editing &&
                <ExpeditionDetails show={true}
                                   saving={saving}
                                   zIndex={1050}
                                   expeditionToEdit={expedition}
                                   onDone={saveExpedition}
                                   onCancel={() => setEditing(false)}
                />
            }
        </>
    );
}

export default ExpeditionItem;
