import React, {FC, useState} from "react";
import {Button, Form, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import MinusIconButton from "../../../common/icon-buttons/MinusIconButton";
import {CustomerInquiryProductsType, CustomerInquiryProductType} from "../../../fb-converters/cutomerInquiry-converter";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faStar} from "@fortawesome/free-solid-svg-icons";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";

interface Props {
    onChange: (products: CustomerInquiryProductsType) => void,
    products: CustomerInquiryProductsType,
}

const CustomerInquiryProducts: FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState(false);

    function addNewProduct() {
        const newProduct: CustomerInquiryProductType = {
            product: null,
            buyback: false,
            name: "",
            comments: "",
            machineDescription: "",
            size: {
                l: "", b: "", h: "", h1: ""
            }
        };
        props.onChange([...props.products, newProduct]);
    }

    function updateInquiry(idx: number, newProduct: CustomerInquiryProductType) {
        const _products = [...props.products];
        if (idx === -1) {
            _products.push(newProduct);
        } else {
            _products[idx] = newProduct;
        }
        props.onChange(_products);
    }

    function removeProduct(idx: number) {
        const _products = [...props.products];
        _products.splice(idx, 1);
        props.onChange(_products);
    }

    function convertProductToInquiryProduct(product: ProductType): CustomerInquiryProductType {
        const _product = {...product} as ProductType;
        return {
            product: _product,
            name: _product.name,
            size: {
                l: _product?._length?.toString() || "",
                b: _product?.width?.toString() || "",
                h: _product?.height1?.toString() || "",
                h1: _product?.height2?.toString() || "",
            },
            buyback: false,
            comments: "",
            machineDescription: "",
        }
    }

    function updateInqueryProducts(result: any) {
        updateInquiry(-1, convertProductToInquiryProduct(result));
    }

    return <>
        <Table>
            <thead>
            <tr>
                <RightAlignedCell header className={"w-50px"} rowSpan={2}>No:</RightAlignedCell>
                <th rowSpan={2}>Име (тип)</th>
                <CenterAlignedCell header colSpan={4}>Размери (mm)</CenterAlignedCell>
                <th rowSpan={2} className={"w-50px text-center"}>Обр.<br/>изк.</th>
                <th rowSpan={2}>Информация за машина</th>
                <th rowSpan={2}/>
            </tr>
            <tr>
                <CenterAlignedCell header className={"w-50px"}>L</CenterAlignedCell>
                <CenterAlignedCell header className={"w-50px"}>B</CenterAlignedCell>
                <CenterAlignedCell header className={"w-50px"}>H1</CenterAlignedCell>
                <CenterAlignedCell header className={"w-50px"}>H2</CenterAlignedCell>
            </tr>
            </thead>
            <tbody>
            {
                props.products.map(
                    (p: CustomerInquiryProductType, idx: number) => <tr key={idx}>
                        <RightAlignedCell><span
                            className={"d-inline-block lh-2-5em"}> {idx + 1} </span></RightAlignedCell>
                        <td>
                            <Form.Control
                                disabled={p.product !== null}
                                value={p.name || ""}
                                onChange={event => {
                                    updateInquiry(idx, {...p, name: event.target.value})
                                }}/>
                        </td>
                        <RightAlignedCell className={"w-60px"}>
                            <Form.Control
                                disabled={p.product !== null}
                                value={p.size?.l || ""}
                                onChange={event => {
                                    updateInquiry(idx, {
                                        ...p,
                                        size: {
                                            l: event.target.value,
                                            b: p.size?.b || "",
                                            h: p.size?.h || "",
                                            h1: p.size?.h1 || ""
                                        }
                                    })
                                }} className={"text-end"}/>
                        </RightAlignedCell>
                        <RightAlignedCell className={"w-60px"}>
                            <Form.Control
                                disabled={p.product !== null}
                                value={p.size?.b || ""}
                                onChange={event => {
                                    updateInquiry(idx, {
                                        ...p,
                                        size: {
                                            l: p.size?.l || "",
                                            b: event.target.value,
                                            h: p.size?.h || "",
                                            h1: p.size?.h1 || ""
                                        }
                                    })
                                }} className={"text-end"}/>
                        </RightAlignedCell>

                        <RightAlignedCell className={"w-60px"}>
                            <Form.Control
                                disabled={p.product !== null}
                                value={p.size?.h || ""}
                                onChange={event => {
                                    updateInquiry(idx, {
                                        ...p,
                                        size: {
                                            l: p.size?.l || "",
                                            b: p.size?.b || "",
                                            h: event.target.value,
                                            h1: p.size?.h1 || ""
                                        }
                                    })
                                }} className={"text-end"}/>
                        </RightAlignedCell>
                        <RightAlignedCell className={"w-60px"}>
                            <Form.Control
                                disabled={p.product !== null}
                                value={p.size?.h1 || ""}
                                onChange={event => {
                                    updateInquiry(idx, {
                                        ...p,
                                        size: {
                                            l: p.size?.l || "",
                                            b: p.size?.b || "",
                                            h: p.size?.h || "",
                                            h1: event.target.value
                                        }
                                    })
                                }} className={"text-end"}/>
                        </RightAlignedCell>
                        <CenterAlignedCell>
                            <div className={"lh-2-5em"}>
                                <Form.Switch checked={p.buyback}
                                             onChange={event =>
                                                 updateInquiry(idx,
                                                     {
                                                         ...p,
                                                         buyback: event.target.checked
                                                     } as CustomerInquiryProductType)}/>
                            </div>
                        </CenterAlignedCell>
                        <td>
                            <Form.Control value={p.comments}
                                          onChange={event => {
                                              updateInquiry(idx, {...p, comments: event.target.value})
                                          }}/>
                        </td>
                        <RightAlignedCell className={"w-50px"}>
                            <MinusIconButton onClick={() => {
                                removeProduct(idx)
                            }}/>
                        </RightAlignedCell>
                    </tr>
                )
            }
            <tr>
                <RightAlignedCell colSpan={9}>
                    <PlusIconButton onClick={() => addNewProduct()}/>
                    &nbsp;
                    <Button variant="outline-secondary" onClick={() => setShowDialog(true)}>
                        <FontAwesomeIcon icon={faList}/></Button>
                </RightAlignedCell>
            </tr>
            </tbody>
        </Table>
        <ProductsDialog show={showDialog} zIndex={1460}
                        onClose={() => setShowDialog(false)}
                        onSelect={result => {
                            if (result) {
                                updateInqueryProducts(result);
                                setShowDialog(false);
                            }
                        }}
                        onMultiSelect={results => {
                            const _products = [...props.products];
                            results.forEach((result: any) => {
                                const newProduct = convertProductToInquiryProduct(result);
                                _products.push(newProduct);
                            });
                            setShowDialog(false);
                            props.onChange(_products);
                        }}
        />
    </>
}

export default CustomerInquiryProducts;
