import {CrmPeriodProps} from "./CrmCalendar";
import {Component, useEffect, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {ARRAY_MONTHS_FULL, translateDay} from "../../common";
import moment from "moment";
import CrmStatisticsViewer from "./CrmStatisticsViewer";


export default function CrmPeriodDayViewer({period, changePeriodType, statistics}: CrmPeriodProps) {

    function showDayDetails(day: number) {
        const newPeriod = {...period, day: day, activity: undefined};
        changePeriodType("activity", newPeriod);
    }

    // debugger
    const _month = (period.month || 0) + 1;
    const _firstDay = moment(`01.${_month?.toString().padStart(2, '0')}.${period.year?.toString()}`, 'DD.MM.YYYY');

    const _rows = [];
    const _stat = statistics.data() || {};
    const _y = 'y' + period.year?.toString();
    const _m = 'm' + period.month?.toString();
    const _data = ((_stat[_y] || {})[_y] || {})[_m] || {};



    while(_firstDay.format('MM') == _month.toString().padStart(2, '0')) {
        const _dayName = translateDay(_firstDay.format('ddd'));
        let _color = (_dayName === "Съб" ||  _dayName === "Нед") ? "bg-light" : "";
        if(moment().isSame(_firstDay)) _color = "bg-primary-light";
        const _dayIdx = parseInt(_firstDay.format('DD'));

        _rows.push(<tr key={_dayIdx} className={_color}>
            <td> <span className={"w-100px d-inline-block"}>{ _firstDay.format('DD.MM.YYYY') }</span>
                <span className={"text-uppercase small text-info"}>{ _dayName }</span> </td>
            <td>
                <CrmStatisticsViewer data={ _data['d' + _dayIdx] } />
            </td>
            <td className={"text-end"}>
                <Button size={"sm"} onClick={e => showDayDetails(_dayIdx)}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
            </td>
        </tr>);
        _firstDay.add(1, "day");
    }


    return <>
        <Row>
            <Col>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Ден</th>
                        <th>Активности</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {_rows}
                    </tbody>

                </Table>
            </Col>
        </Row>
    </>
}