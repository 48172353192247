import {dateToString} from "../../../common";
import {FC} from "react";
import {ProductionCardExpeditionsType} from "../types/ProductionCardType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faInfo} from "@fortawesome/free-solid-svg-icons";

interface Props {
    expeditions: ProductionCardExpeditionsType;
}

export const RenderProductionCardExpeditions: FC<Props> = ({expeditions}) => {
    return (
        <td>
            {
                expeditions?.map((expedition, index) => {
                    const hintText = `Дата: ${dateToString(expedition.expeditionDate)}\nКоличество: ${expedition.qty}\nШофьор: ${expedition.driverName}\nКола: ${expedition.carName}`;
                    return <div key={index} className={"text-nowrap"}>
                        <span>{dateToString(expedition.expeditionDate)}: {expedition.qty} </span>
                        <span>
                            <FontAwesomeIcon icon={faCircleInfo} className={"text-primary"} title={hintText} />
                        </span>
                    </div>
                })
            }
        </td>
    );
}
