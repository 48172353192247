import {Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import EditableTableCell from "../../../common/EditableTableCell";
import {textToFloat, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsNumber} from "../../../common";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import ListIconButton from "../../../common/icon-buttons/ListIconButton";
import React, {useState} from "react";
import {
    CustomerOfferServicesType, CustomerOfferServiceType,
} from "../../../fb-converters/cutomerOffer-converter";
import {ServicesDialog} from "../../nomenclatures/services/ServicesDialog";
import {ServiceType} from "../../../fb-converters/service-converter";
import CenterAlignedCell from "../../../common/CenterAlignedCell";

interface Props {
    zIndex?: number;
    discount_price: number;
    eurExchangeRate: number;
    qty: number;
    services: CustomerOfferServicesType;
    setServices: (services: CustomerOfferServicesType) => void;
}

export default function CustomerOfferDetailsServices({zIndex, discount_price, eurExchangeRate, qty, services, setServices}: Props) {
    const [editCell, setEditCell] = useState("");
    const [showServicesDialog, setShowServicesDialog] = useState(false);

    console.log(services)
    return (
        <>
            <Row>
                <Col xs={"12"}><strong>Услуги</strong></Col>
                <Col xs={"12"}>
                    <Table className={"border"}>
                        <thead>
                        <tr>
                            <RightAlignedCell header className={"w-75px"}>No:</RightAlignedCell>
                            <th>Име</th>
                            <th>Описание</th>
                            <RightAlignedCell header>Ед.цена<br/>(лв)</RightAlignedCell>
                            <RightAlignedCell header>Отстъпка<br/>(%)</RightAlignedCell>
                            <RightAlignedCell header>Цена след<br/>отст., лв</RightAlignedCell>
                            <RightAlignedCell header>Кол.</RightAlignedCell>
                            <RightAlignedCell header>Цена<br/>(лева)</RightAlignedCell>
                            <RightAlignedCell header className={"text-end w-75px"}/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            services?.map(
                                (s, idx) => <tr key={`${idx}`}>
                                    <RightAlignedCell> { 1 + idx } </RightAlignedCell>
                                    <td> { s.service.name } </td>
                                    <td> { s.service.description } </td>
                                    <RightAlignedCell> { zeroToEmptyStringAsCurrencyWithCommas(s.singlePrice.toString()) } </RightAlignedCell>

                                    <EditableTableCell inEditMode={editCell === `${idx}-discount`}
                                                       className={"w-120px text-end"}
                                                       isWorking={false}
                                                       value={zeroToEmptyStringAsCurrencyWithCommas(s.discount.toString())}
                                                       onEdit={() => setEditCell(`${idx}-discount`)}
                                                       onBlur={newValue => {
                                                           const _services = [...services];
                                                           const price = _services[idx].singlePrice || 0;
                                                           const discount = textToFloat(newValue) || 0;
                                                           const finPrice = price - (price * discount / 100);

                                                           _services[idx].discount = discount;
                                                           _services[idx].finalPrice = finPrice;
                                                           setServices(_services);
                                                           setEditCell("");
                                                       }}
                                                       onDone={() => setEditCell("")}/>

                                    <RightAlignedCell> { zeroToEmptyStringAsCurrencyWithCommas(s.finalPrice.toString()) } </RightAlignedCell>
                                    <EditableTableCell
                                        className={"w-120px text-end"}
                                        inEditMode={
                                            editCell === `${idx}-qty`
                                        }
                                        isWorking={
                                            false
                                        }
                                        value={
                                            zeroToEmptyStringAsNumber((s.qty ?? qty).toString())
                                        }
                                        onEdit={
                                            () => setEditCell(`${idx}-qty`)
                                        }
                                        onBlur={
                                            newValue => {
                                                const _services = [...services];
                                                const price = _services[idx].singlePrice || 0;
                                                const discount = _services[idx].discount || 0;
                                                const finPrice = textToFloat((price - (price * discount / 100)).toFixed(2)) ?? 0;
                                                const qty = textToFloat(newValue) || 0;

                                                _services[idx].finalPrice = finPrice;
                                                _services[idx].qty = qty;

                                                setServices(_services);
                                                setEditCell("");
                                            }
                                        }
                                        onDone={
                                            () => setEditCell("")
                                        }
                                    />
                                    <RightAlignedCell> { zeroToEmptyStringAsCurrencyWithCommas((s.finalPrice * (s.qty ?? qty)).toString()) } </RightAlignedCell>
                                    <RightAlignedCell>
                                        {
                                            editCell === `${idx}-remove` ?
                                                <>
                                                    <CheckIconButton onClick={() => {
                                                        const _services = [...services];
                                                        _services.splice(idx, 1);
                                                        setEditCell("");
                                                        setServices(_services);
                                                    }}/>
                                                    <CancelIconButton onClick={() => setEditCell("")}/>
                                                </> :
                                                <TrashIconButton size={"sm"}
                                                                 onClick={() => setEditCell(`${idx}-remove`)}/>
                                        }
                                    </RightAlignedCell>
                                </tr>
                            )
                        }

                        <tr>
                            <RightAlignedCell colSpan={9}>
                                <ListIconButton onClick={() => setShowServicesDialog(true)} variant={"primary"}/>
                            </RightAlignedCell>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>


            <ServicesDialog show={showServicesDialog} zIndex={(zIndex || 1060) + 10}
                                   onClose={() => setShowServicesDialog(false)}
                                   onSelect={(result: ServiceType | null | undefined) => {
                                       if (result) {
                                           const _services = services ? [...services] : [];

                                           let itemIdx = -1;
                                           if(result.isWarrantyService) {
                                               itemIdx = _services.findIndex(s => s.service.isWarrantyService);
                                           }

                                           const price = (result.price || 0) * (eurExchangeRate || 1);
                                           const finalPrice = price - (price * discount_price / 100);

                                           if(itemIdx === -1) {
                                               _services.push({
                                                   service: result,
                                                   discount: discount_price,
                                                   singlePrice: price,
                                                   finalPrice: finalPrice,
                                                   qty: qty,
                                               } as CustomerOfferServiceType);
                                           } else {
                                               _services[itemIdx] = {
                                                   service: result,
                                                   discount: discount_price,
                                                   singlePrice: price,
                                                   finalPrice: finalPrice,
                                                    qty: qty,
                                               } as CustomerOfferServiceType;
                                           }

                                           setServices(_services);
                                           setShowServicesDialog(false);
                                       }
                                   }}
            />
        </>
    )
}
