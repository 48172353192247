import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {OwnCompanyType, ownCopanyConverter} from "../../../fb-converters/own-company-converter";
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {fbDb} from "../../../App";
import {CustomerInvoiceType, EMPTY_CUSTOMER_INVOICE_PARTY} from "../../../fb-converters/cutomerInvoice-converter";
import CustomerInvoiceDetails from "./CustomerInvoiceDetails";

export default function CustomersInvoicesPage(props: {
    inModal?: boolean,
    openMode: "add" | "edit" | "list",
    editInvoice?: CustomerInvoiceType,
    onDone?: VoidFunction,
}) {
    const [invoice, setInvoice] = useState<CustomerInvoiceType | undefined>(props.editInvoice);
    const [ownCompanies, setOwnCompanies] = useState<OwnCompanyType[]>([]);

    async function loadOwnCompanies() {
        const ownCompaniesData = await getDocs(query(collection(fbDb, "ownCompanies"),
            orderBy("listIndex", "asc")).withConverter(ownCopanyConverter));
        setOwnCompanies(ownCompaniesData.docs.map(d => d.data()))
    }

    useEffect(() => {
        loadOwnCompanies();
    }, []);

    function addNewCustomerInvoice() {
        setInvoice({
            invoiceNumber: -1,
            invoiceDate: new Date(),
            documentType: "INVOICE",
            offerId: "",
            offerNumber: -1,
            offerDate: null,
            bank: "",
            bankCode: "",
            dialDescription: "",
            dialPlace: "",
            iban: "",
            dialReason: "",
            items: [],
            payType: "",
            receiver: EMPTY_CUSTOMER_INVOICE_PARTY,
            sender: EMPTY_CUSTOMER_INVOICE_PARTY,
            taxEventDate: new Date(),
            deliveryDate: new Date(),
        } as CustomerInvoiceType);
    }

    return <>
        <Row>
            {!props.inModal && <Col/>}
            <Col xs={props.inModal ? 12 : 8}>
                <Card>
                    <Card.Header>
                        <Card.Title>CRM - Фактуриране</Card.Title>
                    </Card.Header>


                    <Card.Body className={"bg-secondary-light"}>
                        {/*<CustomerInvoicesList*/}
                        {/*    onEdit={inquiry => {*/}
                        {/*        setInvoice(invoice)*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <strong>Тук ще се показват всички издадени фактури...</strong>
                    </Card.Body>

                    <Card.Footer className={"text-end"}>
                        {/*<Button onClick={addNewCustomerInvoice} disabled>*/}
                        {/*    <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;Добави нова*/}
                        {/*</Button>*/}
                    </Card.Footer>
                </Card>
            </Col>
            {!props.inModal && <Col/>}
        </Row>

        {/*{*/}
        {/*    invoice && <CustomerInvoiceDetails*/}
        {/*        availableItems={[]}*/}
        {/*        ownCompanies={ownCompanies}*/}
        {/*        invoice={invoice}*/}
        {/*        onClose={() => setInvoice(undefined)}/>*/}
        {/*}*/}
    </>
}
