import {Button, Col, Form, ModalBody, ModalFooter, Row, Spinner, Tab, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, DocumentData, DocumentReference} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {zeroToEmptyStringAsNumber} from "../../../common";

export interface ProductDimensesListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: ProductDimens) => any;
    onDelete: (item: ProductDimens) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export interface ProductDimens {
    id: string;
    ref: DocumentReference<DocumentData>;
    name: string;
}

export function ProductDimensConvert(_data: any): Array<ProductDimens> {
    let _productDimenses: Array<ProductDimens> = [];

    if (_data) {
        _productDimenses = _data.docs.map(
            (g: any) => {
                return {
                    id: g.id,
                    ref: g.ref,
                    name: g.data().name,
                } as ProductDimens
            }
        )
    }

    return _productDimenses;
}

export function ProductDimensesList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ProductDimensesListProps) {
    const [_dimensesData, loadingDimenses, errorDimenses] = useCollection(
        collection(fbDb, "productDimenses")
    );

    const [dimenses, setDimenses] = useState<Array<ProductDimens>>([]);

    useEffect(
        () => {
            setDimenses(ProductDimensConvert(_dimensesData));
        }, [_dimensesData]
    );

    function isLoading() {
        return loadingDimenses;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorDimenses && <LoadDataFromServerError/>
            }

            {
                !loadingDimenses && !errorDimenses && dimenses && isVisible && dimenses.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма дефинирани мерни единици</Col>
                </Row>
            }


            {
                !loadingDimenses && !errorDimenses && dimenses && isVisible && dimenses.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Мерна единица</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dimenses.length > 0 && dimenses.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                <td>
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(d)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    <Button size={"sm"} variant={"outline-danger"}
                                                            onClick={() => {
                                                                onDelete(d)
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>&nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(d)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {d.name}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    <Button onClick={() => onAdd()}>
                        <FontAwesomeIcon icon={faPlus}/> Добави нова
                    </Button>
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> Затвори
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
